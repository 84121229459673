import React, { Component } from "react";
import Header from "./header";
import ReloadBar from "./reloadBar";
import fire from "../../services/fire";
import SpinnerText from "./spinnerwithText";
import Typist from "react-typist";

class IntroPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };
  }
  componentDidMount() {}

  handleClick = async (user) => {
    const { handler } = this.props;
    this.setState({ submitting: true });
    console.log("Getting Started...");
    const db = fire.firestore();

    const owner = db.collection("users").doc(user.uid);
    await owner.update({
      init: true,
    });
    this.setState({ submitting: false });
    handler();
  };

  render() {
    const { user, version } = this.props;
    const submitting = this.state.submitting;
    return (
      <React.Fragment>
        <Header
          user={user}
          smallpic={user.photoURL}
          hide={true}
          hidehome={true}
        />
        <ReloadBar version={version} />

        <div className="row justify-content-center mt-4 mx-auto appearslowly">
          <div className="col-lg-6">
            <main className="container text-center">
              <div className="text-center">
                <span
                  className="text-center"
                  style={{ fontSize: "80px" }}
                  role="img"
                  aria-label="smile">
                  😊
                </span>
              </div>
              <h3 className="pt-4">Welcome {user.displayName}!</h3>
              <div className="text-center defaultfontsize my-4 px-2 pt-0 pb-0">
                <Typist>
                  Congratulations on joining HealSoc👏 Let's continue to setup
                  your account!
                </Typist>
              </div>
              <div className="py-4 my-4">
                {submitting && (
                  <button className="btn btn-primary btn-lg px-4 py-2" disabled>
                    <SpinnerText text="Getting Started..." />
                  </button>
                )}
                {!submitting && (
                  <button
                    className="btn btn-primary btn-lg px-4 py-2"
                    onClick={() => this.handleClick(user)}>
                    Continue setup
                  </button>
                )}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IntroPage;
