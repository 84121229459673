import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

function ProgressIndicator({ obj }) {
  const ItemObj = ({ completed = false, text = "" }) => {
    return (
      <div className={`text-center col px-1`}>
        {completed ? (
          <CheckCircleIcon
            style={{ fontSize: "40px" }}
            className={`text-success`}
          />
        ) : (
          <CheckCircleOutlineIcon
            style={{ fontSize: "40px" }}
            className={`text-muted`}
          />
        )}
        <div
          className={`standardfontsize text-center ${
            completed ? "text-dark" : "text-muted"
          }`}>
          {text}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="d-flex justify-content-start font-weight-bold text-left defaultfontsize mt-4">
        To Do 👷🏻
      </div>
      <div className="mt-2 mb-3 d-flex justify-content-around">
        <ItemObj text="Photo" obj={obj} completed={obj?.bigpic?.length > 0} />
        <ItemObj text="Experience" obj={obj} completed={obj?.exp?.length > 0} />
        <ItemObj text="Education" obj={obj} completed={obj?.edu?.length > 0} />
        <ItemObj text="Training" obj={obj} completed={obj?.train?.length > 0} />
      </div>
    </div>
  );
}

export default ProgressIndicator;
