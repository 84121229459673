import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import LoadingSpinner from "../../common/loadingSpinner";
import SystemLogo from "../../blocks/systemLogoBlock";
import {
  createWithEmailAndPassword,
  isUserLoggedIn,
} from "../../../services/user";
import { logEvent, logMessage } from "../../../services/log";
import { isValidFullname } from "../../../services/validation";
import { Link } from "react-router-dom";
import countryList from "react-select-country-list";
import Footer from "../../common/footer";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

class RegisterForm extends Form {
  state = {
    countries: countryList()
      .getData()
      .map((item) => {
        return { _id: item.value, name: item.label };
      }),
    showpass: false,
    data: {
      fullname: "",
      userhandle: "",
      email: "",
      password: "",
      country: "",
      bio: "",
    },
    refid: this.props?.match?.params?.refid || "",
    errors: {},
    loading: true,
    emailaccountcreationfailed: false,
    accountcreateerror:
      "Something went wrong. Please try to login, reset your password or use another account.",
    unrecoverableerror: "We are unable to create your account.",
    emailsenterror:
      "There was a problem sending to your email account. Please try to reset your password or use another account.",
  };

  schema = {
    fullname: Joi.string().trim().required().label("Fullname"),
    userhandle: Joi.string()
      .trim()
      .min(3)
      .regex(/^([a-zA-Z0-9_-]+)$/)
      .required()
      .label("Username"),
    email: Joi.string().trim().email().required().label("Email"),
    country: Joi.string().trim().required().label("Country"),
    bio: Joi.string().trim().required().label("Headline"),
    password: Joi.string().trim().required().min(8).label("Password"),
  };

  doSubmit = async () => {
    console.log("Submit");
    const { data, errors, refid } = this.state;
    const reservedwords = [
      "app",
      "apps",
      "android",
      "iphone",
      "ios",
      "windows",
      "fuck",
      "pussy",
      "dick",
      "vagina",
      "penis",
      "ass",
      "asshole",
      "stupid",
      "email",
      "verify",
      "contact",
      "contactus",
      "connect",
      "connections",
      "invite",
      "invites",
      "friend",
      "friends",
      "admin",
      "command",
      "commandcenter",
      "login",
      "register",
      "forgot",
      "password",
      "about",
      "logout",
      "home",
      "welcome",
      "faq",
      "v",
      "a",
      "p",
      "u",
      "user",
      "web",
      "privacy",
      "delete",
      "add",
      "sub",
      "contribute",
      "contributor",
      "welcome1",
      "profile",
      "404",
      "donation",
      "donate",
      "facebook",
      "instagram",
      "uber",
      "tiktok",
      "nokia",
      "gmail",
      "google",
      "heal",
      "sick",
      "healsoc",
      "doctor",
      "nurse",
      "labtech",
    ];
    //trim spaces
    let fullname = data.fullname.trim();
    let email = data.email.toLowerCase().trim();
    let password = data.password.trim();
    let userhandle = data.userhandle.trim().toLowerCase();
    let country = data.country.trim();
    let headline = data.bio.trim();
    let error;

    if (reservedwords.includes(userhandle)) {
      error = {
        userhandle: "Please use another Username.",
      };
      this.setState({
        errors: error,
        loading: false,
      });
      return;
    }
    const result = userhandle.match(/^([a-zA-Z0-9_-]+)$/);
    console.log(result);
    let isvalidfullname = isValidFullname(fullname);
    if (!result) {
      error = {
        userhandle:
          "Min 3 characters. Only dash, underline and alphanumeric characters allowed.",
      };
      this.setState({
        errors: error,
        loading: false,
      });
      return;
    }
    //check if firstname and last name are empty
    if (fullname === "" || !isvalidfullname) {
      error =
        fullname === ""
          ? { fullname: "Please provide Name" }
          : { fullname: "Valid Name only." };
      this.setState({
        data,
        errors: error,
        loading: false,
      });
      return;
    }

    const user = await fire
      .firestore()
      .collection("users")
      .where("userhandle", "==", userhandle)
      .get();

    const list = [];
    user.forEach((doc) => {
      list.push(doc.data());
    });

    if (list.length) {
      error = {
        userhandle: "Username already exists",
      };
      this.setState({
        data,
        errors: error,
        loading: false,
      });
      return;
    }

    this.setState({
      data: {
        email,
        fullname,
        userhandle: userhandle?.trim(),
        password,
        country,
        headline,
      },
      errors,
      loading: true,
    });

    // Call the server
    try {
      let result = await createWithEmailAndPassword(email, password);
      if (result && result.code === "success") {
        logEvent("create_email_password_success", {
          email: email,
        });
        const user = result.user;
        let userid = user.uid;
        await user.updateProfile({
          displayName: fullname,
          userHandle: userhandle?.trim(),
        });

        try {
          const uniqNum = new Date().getTime();
          // console.log("Writing to users collection ");
          //Log to user DB and indicate email verified: false
          await fire.firestore().doc(`users/${userid}`).set({
            email,
            fullname,
            country,
            bio: headline,
            created: uniqNum,
            userid: userid,
            userhandle: userhandle?.trim(),
            edu: [],
            exp: [],
          });
          logEvent("user_account_create_success", {
            email: email,
          });
          // console.log("Before sending email verification");
          try {
            await user.updateProfile({
              displayName: fullname,
              userHandle: userhandle,
            });
            // await user.sendEmailVerification();
            // console.log("Done sending email verification");
            // logEvent("auto_send_email_verification_success", {
            //   email: email,
            // });
            //Route /home will check if email is verified, if not, show Account Verify page
            // let link = `/welcome`;
            let link = `/home`;
            if (refid) {
              link = `/a/${refid}`;
            }
            this.props.history.push(link);
          } catch (error) {
            logEvent("auto_send_email_verification_fail", {
              email: email,
              message: error.message,
            });
            console.error("Error sending email: ", error);

            this.setState({
              emailaccountcreationfailed: true,
              loading: false,
              errors: { email: this.state.emailsenterror },
            });
          }
        } catch (ex) {
          console.error("Error creating email and password: ", ex);
          logEvent("user_account_create_fail", {
            email: email,
            message: ex.message,
          });
          // await logMessage("create_email_and_password_fail_exception", {
          //   email: email,
          //   message: ex.message,
          //   code: ex.code,
          // });
          this.setState({
            emailaccountcreationfailed: true,
            loading: false,
            errors: { email: this.state.accountcreateerror },
          });
        }
      } else {
        logEvent("create_email_password_fail", {
          email: email,
          message: result.message,
        });
        // await logMessage("create_email_and_password_fail", {
        //   email: email,
        //   message: result.message,
        //   code: result.code,
        // });
        console.log("Error:", result);
        this.setState({
          emailaccountcreationfailed: true,
          loading: false,
          errors: result,
        });
      }
    } catch (ex) {
      console.log("Errors:", ex);

      logEvent("create_email_password_fail", {
        email: email,
        message: ex.message,
      });
      // await logMessage("create_email_and_password_fail_exception", {
      //   email: email,
      //   message: ex.message,
      //   code: ex.code,
      // });
      if (ex.hasOwnProperty("code")) {
        console.log("Errors:", ex);
        let errors;
        if (ex.code === "auth/too-many-requests") {
          errors = { email: ex.message };
        } else {
          errors = {
            email: ex.message,
          };
        }
        this.setState({
          loading: false,
          errors,
        });
      } else {
        this.setState({
          loading: false,
          errors: { email: this.state.unrecoverableerror },
        });
      }
    }
  };

  async componentDidMount() {
    const loggedIn = await isUserLoggedIn();
    // console.log("Logged in: ", loggedIn);
    let el = document.querySelector("title");
    const title = `Register | HealSoc`;
    const content = `Join the network of Filipino Health Professionals around the world.`;
    el.innerText = title;
    el = document.querySelector("meta[name='description']");
    el.setAttribute("content", content);

    // const countries = countryList()
    //   .getData()
    //   .map((item) => {
    //     return { _id: item.value, name: item.label };
    //   });
    if (loggedIn) {
      // console.log("Logged in");
      this.props.history.push("/home");
      return;
    }
    this.setState({
      // countries,
      loading: false,
    });
    window.scrollTo(0, 0);
  }

  handleSelectCountry = (code) => {
    this.setState({ country: code });
  };

  render() {
    const { loading, countries, refid } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <div className="my-4 pt-0"></div>
        <SystemLogo refid={refid} />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-2">
                <h4 className="pb-0 mb-0">Create account</h4>
                <p className="text-left text-muted mb-4">
                  It's quick and easy.
                </p>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "fullname",
                    "Fullname",
                    "text",
                    "Your Fullname"
                  )}
                  {this.renderInput(
                    "userhandle",
                    "Username",
                    "text",
                    "Ex. lee"
                  )}
                  {this.renderInput(
                    "bio",
                    "Headline",
                    "text",
                    "ex. Registered Nurse or 4th Year Student"
                  )}
                  {this.renderSelect("country", "Country", countries)}

                  {/* <ReactFlagsSelect
                    selected={country}
                    onSelect={(code) => this.handleSelectCountry(code)}
                    placeholder="Select your Country"
                    className="averagefontsize"
                    id="country"
                  /> */}
                  {/* {this.renderSelect("country", "Your country", countries)} */}
                  {this.renderInput("email", "Email", "text", "Your email")}
                  {this.renderInput(
                    "password",
                    "Password",
                    this.state.showpass ? "text" : "password",
                    "8 characters or more"
                  )}
                  <div
                    onClick={() =>
                      this.setState({ showpass: !this.state.showpass })
                    }
                    className="float-right pointer btn-link mb-4"
                    style={{ marginTop: "-15px" }}>
                    {this.state.showpass === false ? (
                      <>
                        <VisibilityOutlinedIcon /> Show Password
                      </>
                    ) : (
                      <>
                        <VisibilityOffOutlinedIcon /> Hide Password
                      </>
                    )}
                  </div>
                  {!loading && this.renderBlockButton("Create Now")}
                  {loading && this.renderLoadingBlockButton("Creating...")}
                </form>
              </div>

              <div className="pt-2 pb-4 text-center">
                <p className="normalsmallfontsize">
                  Have an account? <Link to={`/login/${refid}`}>Log in</Link>
                </p>
              </div>
              <div className="my-4 py-4">
                <hr />
              </div>
              <Footer />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterForm;
