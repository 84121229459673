import React, { Component } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import Header from "../../common/header";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import MyProfileCardPanel from "../../blocks/myProfileCardPanelBlock";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { logEvent } from "../../../services/log";
import SchoolIcon from "@material-ui/icons/School";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  enableEditBasicProfile,
  enableProfilePhotoUpload,
} from "../../../services/settings";
import { isValidFullname } from "../../../services/validation";
import SystemBarError from "../../common/systemBarError";
import ProfileFullname from "../../profile/component/profileFullname";
import ProfileVerifyStatus from "../../profile/component/profileVerifyStatus";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { Grid, Slider } from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import fire from "../../../services/fire";
import { updateProfilePic } from "../../../services/profile";
import SpinnerText from "../../common/spinnerwithText";
import SuccessMessage from "../../common/successMessageBox";
import { Link } from "react-router-dom";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import DisplayStreak from "../../common/displayStreak";
import { convertFireStorageUrl } from "../../../utils/url";
import IntroPage from "../../common/introPage";
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import Footer from "../../common/footer";
import Input from "../../common/input";
import { getLongMonths } from "../../../utils/utils";
import Select from "../../common/select";
import { isEmpty } from "../../../utils/obj";
import ResizeTextarea from "../../common/resizeTextarea";
import ReactShowMoreText from "react-show-more-text";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";

import {
  dateDifference,
  differenceBetweenDates,
  getAge,
  normaliseDate,
} from "../../../utils/date";
import DatePicker from "../../common/datePicker";
import ProgressIndicator from "../../common/progressIndicator";
import Invite from "../../friends/component/invite";
import FormatColorFillRoundedIcon from "@material-ui/icons/FormatColorFillRounded";
import ColorOptions from "../../common/colorOptions";
import { getColorOptions, getThemeData } from "../../../utils/colors";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import PrintableCV from "../../common/printableCV";
class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        background: 0,
        verified: false,
        bigpic: "",
        smallpic: "",
        fullname: "",
        userhandle: "",
        email: "",
        school: "",
        degree: "",
        description: "",
        currentcompanydesc: "",
        currentcompany: "",
        init: false,
      },
      background: 0,
      updatingbackground: false,
      user: this.props.user,
      imageDataUrl: "",
      showphotoedit: false,
      showbackground: false,
      saving: false,
      loading: true,
      zoom: 1,
      showcopymodal: false,
      maxjobdesclength: 1000,
      errors: {},
      entry: {},
      /** trainings modal */
      trainmodaltitle: "Add Training",
      showdeletetrain: false,
      showmodaltrain: false,
      savingtrain: false,
      deletingtrain: false,
      organisation: "",
      /** experience modal */
      expmodaltitle: "Add Experience",
      showdeleteexp: false,
      showmodalexp: false,
      savingexp: false,
      deletingexp: false,
      company: "",
      location: "",
      description: "",
      skills: "",
      /** school modal */
      schoolmodaltitle: "Add Education",
      showdeleteschool: false,
      showmodalschool: false,
      savingschool: false,
      deletingschool: false,
      school: "",
      degree: "",
      awards: "",
      sdate: "",
      edate: "",
      currentlyworking: false,
      startmonth: "",
      startyear: "",
      endmonth: "",
      endyear: "",
      /** common */
      location: "",
      description: "",
      title: "",
      months: getLongMonths().map((i) => {
        return { _id: i, name: i };
      }),
      years: Array.from(
        { length: 101 },
        (_, i) => new Date().getFullYear() - i
      ).map((i) => {
        return { _id: i, name: i };
      }),
      expectedyears: Array.from(
        { length: 101 },
        (_, i) => new Date().getFullYear() + 10 - i
      ).map((i) => {
        return { _id: i, name: i };
      }),
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);
    const l = getLongMonths().map((i) => {
      return { _id: i, name: i };
    });
    // console.log(l);
    if (user) {
      const {
        train,
        exp,
        edu,
        bio,
        fullname,
        email,
        currentpos,
        bigpic,
        country,
        init,
        smallpic,
        school,
        degree,
        currentcompanydesc,
        currentcompany,
        userhandle,
        verified,
        background,
      } = user;

      let isvalidfullname = isValidFullname(fullname);

      const photoUrl = bigpic;
      let el = document.querySelector("title");
      const title = `${fullname} Profile | HealSoc`;
      const content = `Network of Filipino Health Professionals`;
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);
      let version = await getLatestVersion();
      const countries = countryList().getData();

      let countryStr = "";
      if (countries && country !== "") {
        //get country str
        for (let i = 0; i < countries.length; i++) {
          const item = countries[i];
          if (item.value === country) {
            countryStr = item.label;
            break;
          }
        }
      }

      // console.log(countries);
      // console.log(countryStr);
      this.setState({
        countryStr,
        countries,
        version,
        isvalidfullname,
        // totalnotes,
        // totalreceived,
        // totalsent,
        background: background || 0,
        data: {
          train: train || [],
          verified: verified || false,
          exp,
          userhandle,
          edu,
          bio,
          fullname,
          bigpic: bigpic || "",
          smallpic: smallpic || "",
          background: background || 0,
          email,
          init,
          currentpos,
          // storelink,
          school,
          degree,
          currentcompanydesc,
          currentcompany,
          country,
          countryStr,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      logEvent("logout_click", { email: data.email });
      await userLogout();
      window.scrollTo(0, 0);

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  doEdit = () => {
    this.props.history.push("/account/edit");
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { data } = this.state;
      const file = e.target.files[0];
      let imageDataUrl = await this.readFile(file);

      logEvent("profile_new_photo_upload", { email: data.email });

      this.setState({ imageDataUrl, showphotoedit: true });
    }
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  onClickCancel = () => {
    this.setState({ showphotoedit: false });
  };

  doDeleteSchool = async () => {
    this.setState({ deletingschool: true });
    const userid = this.state.user.uid;
    const edu = this.state.data?.edu || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(edu);
    const newEdu = edu.filter((i) => entry.created !== i.created);

    // console.log(newEdu);
    await fire.firestore().doc(`users/${userid}`).update({
      edu: newEdu,
    });
    this.setState({
      deletingschool: false,
      showdeleteschool: false,
      showmodalschool: false,
      data: { ...this.state.data, edu: newEdu },
    });
  };

  doUpdateBackground = async () => {
    this.setState({ updatingbackground: true });
    const userid = this.state.user.uid;
    const back = this.state?.background || 0;
    console.log(back);
    await fire.firestore().doc(`users/${userid}`).update({
      background: back,
    });
    this.setState({
      updatingbackground: false,
      showbackground: false,
      data: { ...this.state.data, background: back },
    });
  };

  doDeleteExp = async () => {
    this.setState({ deletingexp: true });
    const userid = this.state.user.uid;
    const exp = this.state.data?.exp || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(exp);
    const newExp = exp.filter((i) => entry.created !== i.created);

    // console.log(newExp);
    await fire.firestore().doc(`users/${userid}`).update({
      exp: newExp,
    });
    this.setState({
      deletingexp: false,
      showdeleteexp: false,
      showmodalexp: false,
      data: { ...this.state.data, exp: newExp },
    });
  };

  doDeleteTrain = async () => {
    this.setState({ deletingtrain: true });
    const userid = this.state.user.uid;
    const obj = this.state.data?.train || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(obj);
    const newObj = obj.filter((i) => entry.created !== i.created);

    // console.log(newObj);
    await fire.firestore().doc(`users/${userid}`).update({
      train: newObj,
    });
    this.setState({
      deletingtrain: false,
      showdeletetrain: false,
      showmodaltrain: false,
      data: { ...this.state.data, train: newObj },
    });
  };

  doSaveSchool = async () => {
    console.log("saving");
    const {
      startmonth,
      startyear,
      endmonth,
      endyear,
      school,
      entry,
      degree,
      awards,
      schoolmodaltitle,
    } = this.state;
    let errors = {};
    if (school.trim() === "") {
      errors["school"] = "School must not be empty";
    }

    if (degree.trim() === "") {
      errors["degree"] = "Degree must not be empty";
    }

    if (startmonth.trim() === "") {
      errors["startmonth"] = "Month must not be empty";
    }

    if (startyear.trim() === "") {
      errors["startyear"] = "Year must not be empty";
    }

    if (endyear.trim() === "") {
      errors["endyear"] = "Year must not be empty";
    }

    if (endmonth.trim() === "") {
      errors["endmonth"] = "Month must not be empty";
    }
    //check end
    if (
      new Date(`${startmonth} ${startyear}`).getTime() >
      new Date(`${endmonth} ${endyear}`).getTime()
    ) {
      errors["endyear"] = "End Date can't be earlier that start date";
    }
    // console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingschool: true });
    // console.log(startmonth, startyear, endmonth, endyear, school, degree);
    //save here
    const userid = this.state.user.uid;
    let edu = this.state.data?.edu || [];
    const newEdu = {
      created: new Date().getTime(),
      startdate: new Date(`${startmonth} ${startyear}`).getTime(),
      startmonth,
      startyear,
      endmonth,
      endyear,
      school,
      awards: awards || "",
      degree,
    };
    //save
    if (schoolmodaltitle === "Add Education") {
      edu.push(newEdu);
    } else {
      const tempEdu = edu.filter((i) => entry.created !== i.created);
      tempEdu.push(newEdu);
      edu = tempEdu;
    }
    // console.log(edu);
    //sort
    edu.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(edu);

    await fire.firestore().doc(`users/${userid}`).update({
      edu: edu,
    });
    this.setState({
      startmonth: "",
      startyear: "",
      endmonth: "",
      endyear: "",
      school: "",
      degree: "",
      errors: {},
      awards: awards || "",

      data: { ...this.state.data, edu: edu },
      savingschool: false,
      showmodalschool: false,
    });
  };

  doSaveExp = async () => {
    console.log("saving");
    const {
      sdate,
      edate,
      currentlyworking,
      startmonth,
      startyear,
      endmonth,
      endyear,
      title,
      entry,
      location,
      skills,
      company,
      description,
      expmodaltitle,
    } = this.state;
    let errors = {};
    if (title.trim() === "") {
      errors["title"] = "Title must not be empty";
    }

    if (location.trim() === "") {
      errors["location"] = "Location must not be empty";
    }
    if (company.trim() === "") {
      errors["company"] = "Company must not be empty";
    }

    // console.log(currentlyworking);
    // if (startmonth.trim() === "") {
    //   errors["startmonth"] = "Month must not be empty";
    // }

    // if (startyear.trim() === "") {
    //   errors["startyear"] = "Year must not be empty";
    // }
    if (description?.trim() !== "" && description.length > 1000) {
      errors["description"] = "Exceeded maximum character length of 1,000";
    }

    // if (endyear.trim() === "") {
    //   errors["endyear"] = "Year must not be empty";
    // }

    // if (endmonth.trim() === "") {
    //   errors["endmonth"] = "Month must not be empty";
    // }
    // if (endmonth.trim() !== "" && endyear.trim() === "") {
    //   errors["endyear"] = "Year must not be empty";
    // }
    // if (endmonth.trim() === "" && endyear.trim() !== "") {
    //   errors["endmonth"] = "Month must not be empty";
    // }
    //check end
    // if (
    //   new Date(`${startmonth} ${startyear}`).getTime() >
    //   new Date(`${endmonth} ${endyear}`).getTime()
    // ) {
    //   errors["endyear"] = "End year can't be earlier that start date";
    // }
    if (edate < sdate) {
      errors["edate"] = "End Date can't be earlier that start date";
    }
    console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingexp: true });
    //save here
    const userid = this.state.user.uid;
    let exp = this.state.data?.exp || [];
    const newExp = {
      created: new Date().getTime(),
      sdate: sdate.toString(),
      edate: edate.toString(),
      currentlyworking: currentlyworking || false,
      startdate: sdate.getTime(),
      // startdate: new Date(`${startmonth} ${startyear}`).getTime(),
      startmonth,
      startyear,
      endmonth,
      endyear,
      location,
      skills: skills || "",
      company,
      description: description || "",
      title,
    };
    //save
    if (expmodaltitle === "Add Experience") {
      exp.push(newExp);
    } else {
      const tempExp = exp.filter((i) => entry.created !== i.created);
      tempExp.push(newExp);
      exp = tempExp;
    }
    // console.log(exp);
    //sort
    exp.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(exp);

    await fire.firestore().doc(`users/${userid}`).update({
      exp: exp,
    });
    const defaultDate = normaliseDate(new Date());

    this.setState({
      startmonth: "",
      startyear: "",
      endmonth: "",
      sdate: defaultDate,
      edate: defaultDate,
      currentlyworking: false,
      endyear: "",
      title: "",
      skills: "",
      location: "",
      company: "",
      description: "",
      errors: {},
      data: { ...this.state.data, exp: exp },
      savingexp: false,
      showmodalexp: false,
    });
  };

  doSaveTrain = async () => {
    console.log("saving");
    const {
      sdate,
      edate,
      title,
      entry,
      location,
      organisation,
      description,
      trainmodaltitle,
    } = this.state;
    let errors = {};
    if (title.trim() === "") {
      errors["title"] = "Title must not be empty";
    }

    if (location.trim() === "") {
      errors["location"] = "Location must not be empty";
    }
    if (organisation?.trim() === "") {
      errors["organisation"] = "Organisation must not be empty";
    }

    if (description?.trim() !== "" && description.length > 1000) {
      errors["description"] = "Exceeded maximum character length of 1,000";
    }
    if (edate < sdate) {
      errors["edate"] = "End year can't be earlier that start date";
    }
    // console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingtrain: true });
    //save here
    const userid = this.state.user.uid;
    let obj = this.state.data?.train || [];
    const newObj = {
      created: new Date().getTime(),
      sdate: sdate.toString(),
      edate: edate.toString(),
      startdate: sdate.getTime(),
      location,
      organisation: organisation || "",
      description: description || "",
      title,
    };
    //save
    if (trainmodaltitle === "Add Train") {
      obj.push(newObj);
    } else {
      const tempObj = obj.filter((i) => entry.created !== i.created);
      tempObj.push(newObj);
      obj = tempObj;
    }
    // console.log(obj);
    //sort
    obj.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(obj);

    await fire.firestore().doc(`users/${userid}`).update({
      train: obj,
    });
    this.setState({
      sdate: new Date(),
      edate: new Date(),
      title: "",
      organisation: "",
      location: "",
      description: "",
      errors: {},
      data: { ...this.state.data, train: obj },
      savingtrain: false,
      showmodaltrain: false,
    });
  };

  onClickSave = async () => {
    if (this.editor) {
      const { data } = this.state;

      this.setState({ saving: true });
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const userid = this.state.user.uid;
      const uploadpath = `images/${userid}`;
      const filename = `${userid}.jpeg`;

      fire
        .storage()
        .ref(uploadpath)
        .child(filename)
        .putString(canvasScaled.toDataURL(), "data_url")
        .then(async (snapshot) => {
          await updateProfilePic(userid, filename);
          logEvent("profile_photo_changed_success", { email: data.email });

          this.setState({
            newprofileupload: true,
            saving: false,
            showphotoedit: false,
            photoUrl: canvasScaled.toDataURL(),
          });
        });
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  increaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 3;
    if (zoom < 3) {
      newzoom = zoom + 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  decreaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 1;
    if (zoom > 1) {
      newzoom = zoom - 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  reloadProfile = () => {
    window.location.reload();
  };

  goHome = () => {
    this.props.history.push("/home");
  };
  handleSchoolChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ school: input.value });
  };
  handleSDateChange = (value) => {
    const updatedValue = normaliseDate(value);

    this.setState({ sdate: updatedValue });
    // this.setState({ sdate: updatedValue, edate: updatedValue });
  };

  handleEDateChange = (value) => {
    const updatedValue = normaliseDate(value);

    console.log(updatedValue);
    this.setState({ edate: updatedValue });
  };
  handleCurrentWorkingChange = (value) => {
    // console.log(input.value);
    const working = !this.state.currentlyworking;
    this.setState({ currentlyworking: working });
  };
  handleDegreeChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ degree: input.value });
  };
  handleStartMonthChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ startmonth: input.value });
  };
  handleStartYearChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ startyear: input.value });
  };
  handleEndMonthChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ endmonth: input.value });
  };

  keydownhandler = ({ currentTarget: input }) => {
    console.log(input.value);
    if (
      input?.value?.length &&
      input?.value?.length <= this.state.maxjobdesclength
    ) {
      console.log("Celaring");
      let errors = this.state.errors;
      delete errors["description"];
      this.setState({ errors: errors });
    }
    if (
      input?.value?.length &&
      input?.value?.length > this.state.maxjobdesclength
    ) {
      let errors = this.state.errors;
      errors["description"] = "Exceeded maximum character length of 1,000";
      this.setState({ errors: errors });
    }
  };
  handleEndYearChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ endyear: input.value });
  };
  handleBriefDescChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ awards: input.value });
  };
  handleDescriptionChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ description: input.value });
  };
  handleTitleChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ title: input.value });
  };
  handleSkillsChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ skills: input.value });
  };
  handleCompanyChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ company: input.value });
  };
  handleOrganisationChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ organisation: input.value });
  };
  handleLocationChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ location: input.value });
  };
  handleDescriptionChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ description: input.value });
  };

  confirmDeleteSchool = () => {
    this.setState({ showdeleteschool: true });
  };

  confirmDeleteExp = () => {
    this.setState({ showdeleteexp: true });
  };
  confirmDeleteTrain = () => {
    this.setState({ showdeletetrain: true });
  };

  editSchool = (entry) => {
    // console.log(entry);
    this.setState({
      entry: entry,
      showmodalschool: true,
      schoolmodaltitle: "Edit Education",
      school: entry.school,
      degree: entry.degree,
      startmonth: entry.startmonth,
      startyear: entry.startyear,
      endmonth: entry.endmonth,
      endyear: entry.endyear,
      errors: {},
      savingschool: false,
      awards: entry?.awards,
    });
  };

  editTrain = (entry) => {
    // console.log(entry);
    const sdate = entry?.sdate ? new Date(entry?.sdate) : new Date();
    const edate = entry?.edate ? new Date(entry?.edate) : new Date();
    this.setState({
      entry: entry,
      showmodaltrain: true,
      trainmodaltitle: "Edit Training",
      title: entry.title,
      organisation: entry?.organisation || "",
      location: entry.location,
      sdate: normaliseDate(sdate),
      edate: normaliseDate(edate),
      errors: {},
      savingtrain: false,
      description: entry?.description || "",
    });
  };

  editExp = (entry) => {
    // console.log(entry);
    const sdate = entry?.sdate ? new Date(entry?.sdate) : new Date();
    const edate = entry?.edate ? new Date(entry?.edate) : new Date();
    this.setState({
      entry: entry,
      showmodalexp: true,
      expmodaltitle: "Edit Experience",
      title: entry.title,
      company: entry.company,
      location: entry.location,
      startmonth: entry.startmonth,
      startyear: entry.startyear,
      endmonth: entry.endmonth,
      endyear: entry.endyear,
      skills: entry?.skills || "",
      sdate: normaliseDate(sdate),
      edate: normaliseDate(edate),
      currentlyworking: entry?.currentlyworking || false,
      errors: {},
      savingexp: false,
      degree: entry.degree,
      description: entry?.description || "",
    });
  };

  showBackground = () => {
    this.setState({ showbackground: true });
  };

  copyText = () => {
    // if (text.length > 70) {
    //   //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    //   text = `${this.shorten(text, 83)}... See more.`;
    // }
    const texttocopy = `https://healsoc.com/u/${this.state.data.userhandle}`;
    return texttocopy;
  };

  render() {
    const {
      data,
      loading,
      isvalidfullname,
      photoUrl,
      zoom,
      showphotoedit,
      imageDataUrl,
      saving,
      newprofileupload,
      version,
    } = this.state;

    const enablePicUpload = enableProfilePhotoUpload();
    const { fullname } = data;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          // type="backandlogo"
          hidehome
          hidefriends
          hide="profile"
        />
      );
    }
    const linkcolor = getThemeData(this.state.background)?.linkcolor || "";
    const forecolor = getThemeData(this.state.background)?.foreground || "";
    let showedit = false;
    if (enableEditBasicProfile()) {
      showedit = true;
    }
    if (!data?.init) {
      return (
        <IntroPage
          user={this.props.user}
          version={version}
          // handler={() => {}}
          handler={() => this.props.history.push("/account/edit")}
          // handler={() => this.setState({ data: { ...data, init: true } })}
        />
      );
    }
    if (newprofileupload) {
      return (
        <SuccessMessage
          message="Your photo has been successfully saved!"
          label="Done"
        />
      );
    }

    return (
      <React.Fragment>
        <Header
          hidehome
          hide="profile"
          user={this.state.user}
          shortcode={this.state.data.userhandle}
        />

        <ReloadBar version={version} />

        {!isvalidfullname && (
          <SystemBarError
            text="Please update your Full Name."
            link="/account/edit"
          />
        )}
        <div className="row justify-content-center mx-auto appearslowly">
          <div className="col-lg-8 text-center mx-0 px-0">
            <main className="container text-center mx-auto ">
              {!showphotoedit && (
                <React.Fragment>
                  <div
                    className={`mt-4 roundcornernocolor card p-4 shadow ${
                      getThemeData(this.state.background)?.bgcolor
                    }`}>
                    <div
                      className="pointer"
                      onClick={this.showBackground}
                      style={{ position: "absolute", right: "20px" }}>
                      <OverlayTrigger
                        overlay={<Tooltip>Change Background</Tooltip>}>
                        <FormatColorFillRoundedIcon
                          style={{
                            fontSize: "20px",
                            color: linkcolor,
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                    {/* <nav className="navbar navbar-light flex-column"> */}
                    <h1 className="mx-auto mt-0 text-black-50 text-center">
                      {!enablePicUpload && (
                        <PersonRoundedIcon
                          // className="text-primary"
                          style={{
                            fontSize: "150px",
                          }}
                        />
                      )}
                      {enablePicUpload && (
                        <React.Fragment>
                          {!photoUrl && (
                            <PersonRoundedIcon
                              // className="text-primary"
                              style={{
                                fontSize: "150px",
                                color: linkcolor,
                              }}
                            />
                          )}
                          {photoUrl && (
                            <Image
                              src={convertFireStorageUrl(photoUrl)}
                              width="150px"
                              height="150px"
                              alt=""
                              roundedCircle
                            />
                          )}
                          <div className="image-upload">
                            <label
                              htmlFor="file-input"
                              className="image-upload-icon">
                              <OverlayTrigger
                                overlay={<Tooltip>Upload Photo</Tooltip>}>
                                <AddAPhotoIcon
                                  style={{
                                    color: linkcolor,
                                  }}
                                />
                              </OverlayTrigger>
                            </label>

                            <input
                              id="file-input"
                              type="file"
                              onChange={this.onFileChange}
                              accept="image/*"
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </h1>
                    {/* </nav> */}
                    <div
                      className="d-flex justify-content-center"
                      style={{ color: forecolor }}>
                      <ProfileFullname
                        isvalidfullname={true}
                        fullname={data.storename || fullname}
                      />
                      <Link
                        className="ml-1 mt-2 text-center"
                        to="/account/edit">
                        <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                          <EditIcon
                            style={{
                              fontSize: "18px",
                              color: linkcolor,
                            }}
                          />
                        </OverlayTrigger>
                      </Link>
                    </div>
                    {data?.userhandle && (
                      <div className="d-flex justify-content-center">
                        <div className=" py-1 my-0 ">
                          <a
                            style={{
                              color: forecolor,
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://healsoc.com/u/${data.userhandle}`}>
                            www.healsoc.com/u/{data.userhandle}{" "}
                          </a>
                        </div>
                        <CopyToClipboard
                          text={this.copyText()}
                          onCopy={() => this.setState({ showcopymodal: true })}>
                          <Button variant="pure" className="btn-sm py-0">
                            <div className="float-left">
                              <OverlayTrigger
                                overlay={<Tooltip>Copy Link</Tooltip>}>
                                <FileCopyOutlinedIcon
                                  style={{
                                    color: linkcolor,
                                    fontSize: "18px",
                                    marginTop: "-2px",
                                  }}
                                />
                              </OverlayTrigger>
                            </div>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    )}
                    {data?.bio && (
                      <div>
                        <h5
                          className=" py-1 my-0 "
                          style={{ color: forecolor }}>
                          {data?.bio}
                        </h5>
                      </div>
                    )}
                    {data?.country && (
                      <p
                        className="py-1 mb-1 averagefontsize"
                        style={{ color: forecolor }}>
                        {data?.countryStr}{" "}
                        <span className="">
                          <ReactCountryFlag
                            countryCode={data?.country}
                            className="emojiFlag"
                            style={{
                              fontSize: "1.4em",
                              lineHeight: "1em",
                            }}
                          />
                        </span>
                      </p>
                    )}
                    {/* <Link className="pb-0 text-center" to="/account/edit">
                      Edit Basic Info <EditIcon style={{ fontSize: "16px" }} />
                    </Link> */}
                  </div>
                  {/* <div>
                    {data?.bigpic?.length > 0 && (
                      <div className="card alert-success alert shadow-sm roundcornernocolor py-0 mt-4">
                        <Invite refid={this.state.data.userhandle} />
                      </div>
                    )}
                  </div> */}
                  <div className="d-flex justify-content-center my-4">
                    <PrintableCV user={this.props.user} data={data} />
                  </div>
                  <div>
                    {(data?.bigpic?.length === 0 ||
                      data?.edu?.length === 0 ||
                      data?.exp?.length === 0 ||
                      data?.train?.length === 0) && (
                      <ProgressIndicator obj={data} />
                    )}
                  </div>
                  <div className="pt-0 mb-2 pb-0 d-flex justify-content-between  font-weight-bold text-left defaultfontsize">
                    Work Experience ({data?.exp.length}) 💼
                    <div
                      className=""
                      onClick={() =>
                        this.setState({
                          showmodalexp: true,
                          expmodaltitle: "Add Experience",
                          location: "",
                          company: "",
                          title: "",
                          skills: "",
                          description: "",
                          startmonth: "",
                          startyear: "",
                          endmonth: "",
                          endyear: "",
                          sdate: normaliseDate(new Date()),
                          edate: normaliseDate(new Date()),
                        })
                      }>
                      <AddRoundedIcon
                        className="pointer text-primary"
                        fontSize="large"
                      />
                    </div>
                  </div>
                  {data?.exp?.length ? (
                    <div className="mt-0 mb-4 roundcornernocolor card p-4 text-left shadow">
                      {data?.exp?.map((i, index) => {
                        // console.log(i);
                        const skills = i?.skills?.trim() || "";

                        const sDate = i?.sdate
                          ? new Date(i?.sdate).toDateString()
                          : new Date().toDateString();
                        const sDateStr = sDate.slice(4);
                        const eDate = i?.edate
                          ? new Date(i?.edate).toDateString()
                          : new Date().toDateString();

                        const eDateStr =
                          i?.currentlyworking === false ? eDate : "Present";
                        const timeframe = dateDifference(sDateStr, eDateStr);
                        return (
                          <div key={i?.created}>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 font-weight-bold text-left ">
                                {i?.title}
                              </p>
                              <EditOutlinedIcon
                                className="pointer"
                                style={{ fontSize: "20px" }}
                                onClick={() => this.editExp(i)}
                              />
                            </div>
                            <p className="mb-0  text-left  normalsmallerfontsize">
                              {i?.company}
                            </p>
                            <p className="mb-0 text-muted text-left  normalsmallerfontsize">
                              {`${sDateStr} - ${
                                eDateStr === "Present"
                                  ? eDateStr
                                  : eDateStr.slice(4)
                              }`}

                              {timeframe !== "" && (
                                <>
                                  <span className="mx-1">&bull;</span>
                                  <span> {timeframe}</span>
                                </>
                              )}
                            </p>
                            <p className="mb-0 text-muted text-left normalsmallerfontsize">
                              {i?.location}
                            </p>
                            {/* <div 
                             className={`mt-2 mb-0  text-left twolineentry`}> */}
                            <div className="mt-2">
                              {i?.description?.split("\n").map((str, id) => (
                                <p key={id} className="  text-left">
                                  {str}
                                </p>
                              ))}
                            </div>
                            {skills?.trim() !== "" && (
                              <p className="mb-0 text-dark text-left">
                                <b>Skills:</b> {skills}
                              </p>
                            )}
                            {index !== data?.exp?.length - 1 && <hr />}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    // <div className="mb-4 roundcornernocolor card p-4 text-left shadow">
                    //   {data?.currentpos && (
                    //     <p className="mb-0 font-weight-bold text-left ">
                    //       {data?.currentpos}
                    //     </p>
                    //   )}
                    //   {data?.currentcompany && (
                    //     <p className="mb-0 font-weight-bold text-left ">
                    //       {data?.currentcompany}
                    //     </p>
                    //   )}
                    //   {data?.currentcompanydesc && (
                    //     <p className="mt-2 mb-0  text-left ">
                    //       {data?.currentcompanydesc}
                    //     </p>
                    //   )}
                    // </div>
                    <div className="mb-4 text-muted roundcornernocolor card p-4 text-center shadow">
                      {/* <h1>🚚</h1>
                      <Link className="pb-0 text-center" to="/account/edit">
                        Edit Basic Info <EditIcon style={{ fontSize: "16px" }} />
                      </Link> */}
                      Let's start adding your experiences...
                    </div>
                  )}
                  <div className="pt-2 mb-2 d-flex justify-content-between font-weight-bold text-left defaultfontsize">
                    Education ({data?.edu.length}) 🎓
                    <div
                      className=""
                      onClick={() =>
                        this.setState({
                          showmodalschool: true,
                          schoolmodaltitle: "Add Education",
                          school: "",
                          degree: "",
                          startmonth: "",
                          startyear: "",
                          endmonth: "",
                          endyear: "",
                          awards: "",
                        })
                      }>
                      <AddRoundedIcon
                        className="pointer text-primary"
                        fontSize="large"
                      />
                    </div>
                  </div>
                  {data?.edu?.length ? (
                    <div className="mt-0 mb-4 roundcornernocolor card p-4 text-left shadow">
                      {data?.edu.map((i, index) => {
                        // console.log(index);
                        return (
                          <div key={i.created}>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 font-weight-bold text-left ">
                                {i?.school}
                              </p>
                              <EditOutlinedIcon
                                className="pointer"
                                style={{ fontSize: "20px" }}
                                onClick={() => this.editSchool(i)}
                              />
                            </div>
                            <p className="mb-0  text-left  normalsmallerfontsize">
                              {i?.degree}
                            </p>
                            <p className="mb-0 text-muted text-left  normalsmallerfontsize">
                              {`${i?.startmonth.substring(0, 3)} ${
                                i?.startyear
                              } - ${i?.endmonth.substring(0, 3)} ${i?.endyear}`}
                            </p>
                            {i?.awards?.trim() !== "" && (
                              <p className="mt-2 mb-0 text-dark text-left normalsmallerfontsize">
                                {i?.awards?.trim()}
                              </p>
                            )}
                            {index !== data?.edu?.length - 1 && <hr />}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mb-4 text-muted roundcornernocolor card p-4 text-center shadow">
                      {/* <h1>🛵</h1>
                      <Link className="pb-0 text-center" to="/account/edit">
                        Edit Basic Info <EditIcon style={{ fontSize: "16px" }} />
                      </Link> */}
                      Let's start adding your educational background...
                    </div>
                  )}
                  <div className="pt-2 mb-2 d-flex justify-content-between font-weight-bold text-left defaultfontsize">
                    Trainings ({data?.train.length}) 📚
                    <div
                      className=""
                      onClick={() =>
                        this.setState({
                          showmodaltrain: true,
                          trainmodaltitle: "Add Training",
                          title: "",
                          description: "",
                          organisation: "",
                          location: "",
                          sdate: normaliseDate(new Date()),
                          edate: normaliseDate(new Date()),
                        })
                      }>
                      <AddRoundedIcon
                        className="pointer text-primary"
                        fontSize="large"
                      />
                    </div>
                  </div>
                  {data?.train?.length ? (
                    <div className="mt-0 mb-4 roundcornernocolor card p-4 text-left shadow">
                      {data?.train?.map((i, index) => {
                        // console.log(i);
                        const skills = i?.skills?.trim() || "";

                        const sDate = i?.sdate
                          ? new Date(i?.sdate).toDateString()
                          : new Date().toDateString();
                        const sDateStr = sDate.slice(4);
                        const eDate = i?.edate
                          ? new Date(i?.edate).toDateString()
                          : new Date().toDateString();

                        const eDateStr = eDate;
                        return (
                          <div key={i?.created}>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 font-weight-bold text-left ">
                                {i?.title}
                              </p>
                              <EditOutlinedIcon
                                className="pointer"
                                style={{ fontSize: "20px" }}
                                onClick={() => this.editTrain(i)}
                              />
                            </div>
                            <p className="mb-0  text-left  normalsmallerfontsize">
                              {i?.organisation}
                            </p>
                            <p className="mb-0 text-muted text-left  normalsmallerfontsize">
                              {`${sDateStr} - ${
                                eDateStr === "Present"
                                  ? eDateStr
                                  : eDateStr.slice(4)
                              }`}

                              {/* <span className="mx-1">&bull;</span>
                              {dateDifference(sDateStr, eDateStr)} */}
                            </p>
                            <p className="mb-0 text-muted text-left normalsmallerfontsize">
                              {i?.location}
                            </p>
                            {/* <div 
                             className={`mt-2 mb-0  text-left twolineentry`}> */}
                            <div className="mt-2">
                              {i?.description?.split("\n").map((str, id) => (
                                <p key={id} className="  text-left">
                                  {str}
                                </p>
                              ))}
                            </div>

                            {index !== data?.train?.length - 1 && <hr />}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mb-4 text-muted roundcornernocolor card p-4 text-center shadow">
                      Let's start adding your trainings...
                    </div>
                  )}
                  <div className="mt-4 pt-4"></div>
                  <div className="pointer mt-4 pt-4" onClick={this.doSubmit}>
                    Logout your account <ExitToAppIcon />
                  </div>
                  <div className="pt-4 mt-4"></div>
                  <Footer />
                </React.Fragment>
              )}
              {showphotoedit && (
                <div className="mx-auto">
                  <h4 className="text-left py-4">Update Photo</h4>
                  <div className="pb-4">
                    <AvatarEditor
                      ref={this.setEditorRef}
                      image={imageDataUrl}
                      width={250}
                      height={250}
                      border={0}
                      borderRadius={250}
                      scale={zoom}
                      crossOrigin="anonymous"
                    />
                  </div>
                  <Grid container spacing={2} className="my-2">
                    <Grid item>
                      <RemoveIcon onClick={this.decreaseZoom} />
                    </Grid>
                    <Grid item xs>
                      <Slider
                        className="text-primary"
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                      />
                    </Grid>
                    <Grid item>
                      <AddIcon onClick={this.increaseZoom} />
                    </Grid>
                  </Grid>

                  {!saving && (
                    <Button
                      variant="primary"
                      className="btn-block btn-lg"
                      onClick={this.onClickSave}>
                      Save
                    </Button>
                  )}
                  {saving && (
                    <Button
                      variant="primary"
                      className="btn-block btn-lg"
                      disabled>
                      <SpinnerText text="Saving..." />
                    </Button>
                  )}
                  <Button
                    variant="link"
                    className="btn-block btn-lg text-primary"
                    onClick={this.onClickCancel}>
                    Cancel
                  </Button>
                </div>
              )}
            </main>
          </div>
        </div>
        {/* Experience Start */}
        <Modal
          size="lg"
          show={this.state.showmodalexp}
          onHide={() => {
            this.setState({ showmodalexp: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              {this.state.expmodaltitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left">
            <Input
              type="text"
              name="title"
              value={this.state.title}
              label="Title"
              placeholder="Ex: Staff Nurse"
              onChange={this.handleTitleChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["title"]}
            />
            <Input
              type="text"
              name="company"
              value={this.state.company}
              label="Company Name"
              placeholder="Ex: Cebu Doctors Hospital"
              onChange={this.handleCompanyChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["company"]}
            />
            <Input
              type="text"
              name="location"
              value={this.state.location}
              label="Location"
              placeholder="Ex: Cebu City"
              onChange={this.handleLocationChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["location"]}
            />
            <div className="container">
              <div className="row">
                <div className="form-group col mb-0 pb-3 pl-0 pr-0">
                  <label htmlFor="sdate">
                    <div className="pr-2">Start Date</div>
                  </label>
                  <DatePicker
                    name="sdate"
                    selected={
                      this.state?.sdate
                        ? normaliseDate(new Date(this.state?.sdate))
                        : normaliseDate(new Date())
                    }
                    onChange={this.handleSDateChange}

                    // isErr={this.state.errors["sdate"] !== ""}
                  />
                </div>
                <div className="form-group col mb-0 pb-3 pr-0">
                  <label htmlFor="edate">
                    <div className="pr-2">End Date</div>
                  </label>
                  <DatePicker
                    disabled={this.state.currentlyworking === true}
                    name="edate"
                    selected={
                      this.state?.edate
                        ? normaliseDate(new Date(this.state?.edate))
                        : normaliseDate(new Date())
                    }
                    onChange={this.handleEDateChange}
                    isErr={this.state.errors["edate"]}
                  />
                </div>
              </div>
              <div className="row">
                {this.state.errors["edate"] && (
                  <p className="standardfontsize font-weight-normal text-danger">
                    *{this.state.errors["edate"]}
                  </p>
                )}
              </div>
              <div className="row">
                <>
                  <div className="text-center custom-control-lg custom-control custom-checkbox mb-4">
                    <input
                      className="custom-control-input active"
                      value={this.state.currentlyworking}
                      id="checkbox-large"
                      type="checkbox"
                      onChange={this.handleCurrentWorkingChange}
                      checked={this.state.currentlyworking}
                    />
                    <label
                      className="custom-control-label active"
                      htmlFor="checkbox-large">
                      <p className="pointer">I currently work here</p>
                    </label>
                  </div>
                </>
              </div>
            </div>

            <ResizeTextarea
              onKeyDown={(e) => this.keydownhandler(e)}
              type="text"
              name="description"
              value={this.state.description}
              label="Job Description"
              placeholder=""
              onChange={this.handleDescriptionChange}
              error={this.state.errors["description"]}
            />
            <p
              className={`text-right font-weight-normal  ${
                this.state?.description?.length > this.state.maxjobdesclength
                  ? "text-danger"
                  : "text-dark"
              }`}>
              {this.state?.description?.length || "0"}/
              {this.state.maxjobdesclength}
            </p>
            <Input
              type="text"
              name="skills"
              value={this.state.skills}
              label="Skills"
              placeholder=""
              onChange={this.handleSkillsChange}
              // onBlur={this.handleBlur}
              // error={this.state.errors["skills"]}
            />
          </Modal.Body>
          <div
            className={`d-flex ${
              this.state.expmodaltitle === "Add Experience"
                ? "justify-content-end"
                : "justify-content-between"
            }  px-4 pb-4 `}>
            {this.state.expmodaltitle === "Edit Experience" && (
              <Button
                variant="pure"
                style={{ fontSize: "16px" }}
                onClick={this.confirmDeleteExp}>
                <DeleteForeverOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                Delete
              </Button>
            )}
            <div>
              <Button
                disabled={this.state.savingexp}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showmodalexp: false, errors: {} });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.savingexp !== true && (
                <Button
                  variant={`${this.state.showdeleteexp ? "" : "success"}`}
                  onClick={() => this.doSaveExp()}>
                  Save
                </Button>
              )}
              {this.state.savingexp === true && (
                <Button
                  disabled
                  variant="success"
                  onClick={() => this.doSaveExp()}>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Saving...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showdeleteexp}
          onHide={() => {
            this.setState({ showdeleteexp: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Delete Experience?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left text-danger">
            Are you sure you want to delete {this.state.entry?.company}{" "}
            experience?
          </Modal.Body>
          <div className="d-flex justify-content-end px-4 pb-4 ">
            <div>
              <Button
                disabled={this.state.deletingexp}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showdeleteexp: false, errors: {} });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.deletingexp !== true && (
                <Button variant="danger" onClick={() => this.doDeleteExp()}>
                  Delete
                </Button>
              )}
              {this.state.deletingexp === true && (
                <Button disabled variant="danger">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Deleting...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        {/* Experience End */}

        {/* Education Start */}
        <Modal
          size="lg"
          show={this.state.showmodalschool}
          onHide={() => {
            this.setState({ showmodalschool: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              {this.state.schoolmodaltitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left">
            <Input
              type="text"
              name="school"
              value={this.state.school}
              label="School"
              placeholder="Ex: Bohol Island State University"
              onChange={this.handleSchoolChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["school"]}
            />
            <Input
              type="text"
              name="degree"
              value={this.state.degree}
              label="Degree or Course or Field"
              placeholder="Ex: BS in Nursing"
              onChange={this.handleDegreeChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["degree"]}
            />
            <div className="container">
              <div className="row">
                <div className="col pl-0">
                  <Select
                    name="startmonth"
                    value={this.state.startmonth}
                    label="Start Month"
                    options={this.state.months}
                    onChange={this.handleStartMonthChange}
                    // onBlur={this.handleBlur}
                    error={this.state.errors["startmonth"]}
                  />
                </div>
                <div className="col pr-0">
                  <Select
                    name="startyear"
                    value={this.state.startyear}
                    label="Start Year"
                    options={this.state.years}
                    onChange={this.handleStartYearChange}
                    // onBlur={this.handleBlur}
                    error={this.state.errors["startyear"]}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col pl-0">
                  <Select
                    name="endmonth"
                    value={this.state.endmonth}
                    label="End Month"
                    options={this.state.months}
                    onChange={this.handleEndMonthChange}
                    // onBlur={this.handleBlur}
                    error={this.state.errors["endmonth"]}
                  />
                </div>
                <div className="col pr-0">
                  <Select
                    name="endyear"
                    value={this.state.endyear}
                    label="End Year"
                    labelextra={<span className="text-dark"> *</span>}
                    options={this.state.expectedyears}
                    onChange={this.handleEndYearChange}
                    // onBlur={this.handleBlur}
                    error={this.state.errors["endyear"]}
                  />
                </div>
              </div>
              <div className="font-weight-normal standardfontsize row">
                <div className="text-muted">*</div> or expected date if still
                studying
              </div>
            </div>
            <div className="mt-3">
              <Input
                type="text"
                name="awards"
                value={this.state.awards}
                label="Honors/Awards/Achievements"
                placeholder=""
                onChange={this.handleBriefDescChange}
                // onBlur={this.handleBlur}
                // error={this.state.errors["awards"]}
              />
            </div>
          </Modal.Body>
          <div
            className={`d-flex ${
              this.state.schoolmodaltitle === "Add Education"
                ? "justify-content-end"
                : "justify-content-between"
            }  px-4 pb-4 `}>
            {this.state.schoolmodaltitle === "Edit Education" && (
              <Button
                variant="pure"
                style={{ fontSize: "16px" }}
                onClick={this.confirmDeleteSchool}>
                <DeleteForeverOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                Delete
              </Button>
            )}
            <div>
              <Button
                disabled={this.state.savingschool}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showmodalschool: false, errors: {} });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.savingschool !== true && (
                <Button
                  variant={`${this.state.showdeleteschool ? "" : "success"}`}
                  onClick={() => this.doSaveSchool()}>
                  Save
                </Button>
              )}
              {this.state.savingschool === true && (
                <Button
                  disabled
                  variant="success"
                  onClick={() => this.doSaveSchool()}>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Saving...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showdeleteschool}
          onHide={() => {
            this.setState({ showdeleteschool: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Delete Education?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left text-danger">
            Are you sure you want to delete {this.state.entry?.school}?
          </Modal.Body>
          <div className="d-flex justify-content-end px-4 pb-4 ">
            <div>
              <Button
                disabled={this.state.deletingschool}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showdeleteschool: false, errors: {} });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.deletingschool !== true && (
                <Button variant="danger" onClick={() => this.doDeleteSchool()}>
                  Delete
                </Button>
              )}
              {this.state.deletingschool === true && (
                <Button disabled variant="danger">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Deleting...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showbackground}
          onHide={() => {
            this.setState({ showbackground: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Select Background
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left">
            <ColorOptions
              selected={this.state.background}
              handler={(o) => {
                console.log(o);
                this.setState({ background: parseInt(o.id) });
              }}
            />
          </Modal.Body>
          <div className="d-flex justify-content-end px-4 pb-4 ">
            <div>
              <Button
                disabled={this.state.updatingbackground}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showbackground: false });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.updatingbackground !== true && (
                <Button
                  variant="success"
                  onClick={() => this.doUpdateBackground()}>
                  Update
                </Button>
              )}
              {this.state.updatingbackground === true && (
                <Button disabled variant="success">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Updating...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        {/* Education End */}
        {/* Training Start */}
        <Modal
          size="lg"
          show={this.state.showmodaltrain}
          onHide={() => {
            this.setState({ showmodaltrain: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              {this.state.trainmodaltitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left">
            <Input
              type="text"
              name="title"
              value={this.state.title}
              label="Title"
              placeholder="Ex: Mental Health"
              onChange={this.handleTitleChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["title"]}
            />
            <Input
              type="text"
              name="organisation"
              value={this.state.organisation}
              label="Conducting Organisation"
              placeholder="Ex: Cebu Doctors Hospital"
              onChange={this.handleOrganisationChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["company"]}
            />
            <Input
              type="text"
              name="location"
              value={this.state.location}
              label="Location"
              placeholder="Ex: Cebu City"
              onChange={this.handleLocationChange}
              // onBlur={this.handleBlur}
              error={this.state.errors["location"]}
            />
            <div className="container">
              <div className="row">
                <div className="form-group col mb-0 pb-3 pl-0 pr-0">
                  <label htmlFor="sdate">
                    <div className="pr-2">Start Date</div>
                  </label>
                  <DatePicker
                    name="sdate"
                    selected={
                      this.state?.sdate
                        ? normaliseDate(new Date(this.state?.sdate))
                        : normaliseDate(new Date())
                    }
                    onChange={this.handleSDateChange}

                    // isErr={this.state.errors["sdate"] !== ""}
                  />
                </div>
                <div className="form-group col  mb-0 pb-3 pr-0">
                  <label htmlFor="edate">
                    <div className="pr-2">End Date</div>
                  </label>
                  <DatePicker
                    // disabled={this.state.currentlyworking === true}
                    name="edate"
                    selected={
                      this.state?.edate
                        ? normaliseDate(new Date(this.state?.edate))
                        : normaliseDate(new Date())
                    }
                    onChange={this.handleEDateChange}
                    isErr={this.state.errors["edate"]}

                    // minDate={
                    //   this.state?.edate
                    //     ? new Date(this.state?.edate)
                    //     : new Date()
                    // }

                    // isErr={this.state.errors["edate"] !== ""}
                  />
                </div>
              </div>
              <div className="row">
                {this.state.errors["edate"] && (
                  <p className="standardfontsize font-weight-normal text-danger">
                    *{this.state.errors["edate"]}
                  </p>
                )}
              </div>
            </div>

            <Input
              type="text"
              name="description"
              value={this.state.description}
              label="Description"
              placeholder=""
              onChange={this.handleDescriptionChange}
              // onBlur={this.handleBlur}
              // error={this.state.errors["skills"]}
            />
          </Modal.Body>
          <div
            className={`d-flex ${
              this.state.trainmodaltitle === "Add Training"
                ? "justify-content-end"
                : "justify-content-between"
            }  px-4 pb-4 `}>
            {this.state.trainmodaltitle === "Edit Training" && (
              <Button
                variant="pure"
                style={{ fontSize: "16px" }}
                onClick={this.confirmDeleteTrain}>
                <DeleteForeverOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                Delete
              </Button>
            )}
            <div>
              <Button
                disabled={this.state.savingtrain}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showmodaltrain: false, errors: {} });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.savingtrain !== true && (
                <Button
                  variant={`${this.state.showdeletetrain ? "" : "success"}`}
                  onClick={() => this.doSaveTrain()}>
                  Save
                </Button>
              )}
              {this.state.savingtrain === true && (
                <Button
                  disabled
                  variant="success"
                  onClick={() => this.doSaveTrain()}>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Saving...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showdeletetrain}
          onHide={() => {
            this.setState({ showdeletetrain: false });
          }}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Delete Training?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-weight-bold mt-0 mb-4 text-left text-danger">
            Are you sure you want to delete training {this.state.entry?.title}?
          </Modal.Body>
          <div className="d-flex justify-content-end px-4 pb-4 ">
            <div>
              <Button
                disabled={this.state.deletingtrain}
                variant="link"
                className="forcenoline noline mr-2"
                onClick={() => {
                  this.setState({ showdeletetrain: false, errors: {} });
                }}>
                <span className="text-muted forcenoline noline">Cancel</span>
              </Button>
              {this.state.deletingtrain !== true && (
                <Button variant="danger" onClick={() => this.doDeleteTrain()}>
                  Delete
                </Button>
              )}
              {this.state.deletingtrain === true && (
                <Button disabled variant="danger">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>{" "}
                  Deleting...
                </Button>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showcopymodal}
          onHide={() => this.setState({ showcopymodal: false })}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Link Copied
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-0 mb-4 text-center">
            <CheckCircleIcon
              className="text-success"
              style={{ fontSize: "50px" }}
            />
            <br />
            <br />
            You may now share this link with your friends
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="link"
              className="forcenoline noline mr-2"
              onClick={() => this.setState({ showcopymodal: false })}>
              <span className="text-muted forcenoline noline">Got it</span>
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Training End */}
      </React.Fragment>
    );
  }
}

export default UserProfilePage;
