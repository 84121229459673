import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { Button, Image, Modal } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
// import Pdf from "react-to-pdf";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Link } from "react-router-dom";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { dateDifference } from "../../utils/date";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import fire from "../../services/fire";
import { getCurrentUserData } from "../../services/user";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { Margin, usePDF } from "react-to-pdf";
// import generatePDF, { Resolution, Margin, usePDF } from "react-to-pdf";
import { getThemeData } from "../../utils/colors";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import FileSaver from "file-saver";
import PrintableCV from "./printableCV";
import CopyToClipboard from "react-copy-to-clipboard";

// const options = {
//   // default is `save`
//   method: "open",
//   // default is Resolution.MEDIUM = 3, which should be enough, higher values
//   // increases the image quality but also the size of the PDF, so be careful
//   // using values higher than 10 when having multiple pages generated, it
//   // might cause the page to crash or hang.
//   resolution: Resolution.HIGH,
//   page: {
//     // margin is in MM, default is Margin.NONE = 0
//     margin: Margin.LARGE,
//     // default is 'A4'
//     // format: "letter",
//     // default is 'portrait'
//     orientation: "landscape",
//   },
//   canvas: {
//     // default is 'image/jpeg' for better size performance
//     mimeType: "image/png",
//     qualityRatio: 1,
//   },
//   // Customize any value passed to the jsPDF instance and html2canvas
//   // function. You probably will not need this and things can break,
//   // so use with caution.
//   overrides: {
//     // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
//     pdf: {
//       compress: true,
//     },
//     // see https://html2canvas.hertzen.com/configuration for more options
//     canvas: {
//       useCORS: false,
//     },
//   },
// };

// you can use a function to return the target element besides using React refs
const getTargetElement = () => document.getElementById("content-id");

function CVBlockV1({ user, state, inputdata }) {
  const [showmodal, setShowModal] = useState(false);
  // const pdfRef = useRef();
  const [isFriend, setIsFriend] = useState(false);
  const [done, setIsDone] = useState(false);
  const copyText = () => {
    // if (text.length > 70) {
    //   //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    //   text = `${this.shorten(text, 83)}... See more.`;
    // }
    const texttocopy = `https://healsoc.com/u/${inputdata.userhandle}`;
    return texttocopy;
  };
  // const { toPDF, targetRef } = usePDF({
  //   method: "save",
  //   filename: "resume.pdf",
  //   page: { margin: Margin.MEDIUM },
  // });
  const theme = getThemeData(inputdata?.background) || "";
  const forecolor = getThemeData(inputdata?.background)?.foreground || "";
  const linkcolor = getThemeData(inputdata?.background)?.linkcolor || "";
  // console.log(forecolor);
  // console.log(theme);
  const bgcolor =
    theme?.bgcolor !== ""
      ? `${theme?.bgcolor} shadow-sm`
      : " bg-white shadow-sm";
  // console.log(bgcolor);
  // const friend = [];
  const options = {
    filename: "test.pdf",
  };
  useEffect(() => {
    const getDatas = async () => {
      const db = fire.firestore();
      // console.log(user);
      // console.log(inputdata);

      if (user) {
        const result = await db
          .doc(`followings/${user.uid}/userfollowings/${inputdata.userid}`)
          .get();

        if (result && result.exists) {
          setIsFriend(true);
          console.log("Friends....");
        }
      }
      setIsDone(true);
      // setFriend(obj);
    };
    getDatas();
  }, []);

  const handleAddFriend = async () => {
    //handle add friend
    console.log(user);
    console.log(inputdata);
    //check if already friend
    const db = fire.firestore();
    const followers = await db
      .doc(`followers/${inputdata.userid}/userfollowers/${user.uid}`)
      .get();
    let isFollowing = false;
    if (followers && followers.exists) {
      isFollowing = true;
      console.log("following");
    } else {
      //following
      const userObj = await getCurrentUserData(user.uid);

      await db
        .doc(`followers/${inputdata.userid}/userfollowers/${user.uid}`)
        .set({
          friendid: userObj.userid,
          smallpic: userObj?.smallpic || "",
          fullname: userObj?.fullname,
          country: userObj?.country,
          bio: userObj?.bio,
          userhandle: userObj?.userhandle,
        });
      //follower here
      await db
        .doc(`followings/${user.uid}/userfollowings/${inputdata.userid}`)
        .set({
          friendid: inputdata.userid,
          smallpic: inputdata?.smallpic || "",
          fullname: inputdata?.fullname,
          country: inputdata?.country,
          bio: inputdata?.bio,
          userhandle: inputdata?.userhandle,
        });

      setIsFriend(true);
    }
  };

  const downloadPDF = () => {
    // const input = pdfRef.current;

    const capture = document.querySelector(".toPDF");
    if (capture) {
      html2canvas(capture).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;

        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("cv.pdf");
      });
    }
  };

  const doSavePDF = async () => {
    console.log("Calling doSavePDF");
    const savePDf = fire.functions("asia-east2").httpsCallable("savePDF");

    const pdf = await savePDf({
      // obj: { userhandle: "google" },
      // obj: { userhandle: "catholic" },
      obj: { userhandle: inputdata.userhandle },
    });
    console.log(pdf);

    const o = Buffer.from(pdf.data, "base64");
    console.log(o);
    const blob = new Blob([o], {
      type: "application/pdf",
    });
    FileSaver.saveAs(blob, "resume.pdf");
  };

  const photoUrl = inputdata.bigpic;
  let link = "/connect";
  if (user?.uid !== inputdata?.userid) {
    link = `/connections/${inputdata?.userhandle}`;
  }
  let display = null;
  if (state === "ready") {
    display = (
      <div className="toPDF" id="content-id">
        <div className={`mb-2 text-center py-4 ${bgcolor}`}>
          <div className="text-center d-flex justify-content-center">
            {!photoUrl && (
              <PersonRoundedIcon
                className="text-center"
                style={{
                  fontSize: "150px",
                  color: linkcolor,
                }}
              />
            )}
            {photoUrl && (
              <Image
                src={photoUrl}
                width="150px"
                height="150px"
                alt=""
                className="text-center"
                roundedCircle
              />
            )}
          </div>
          <h2 className="pt-1 no mb-0" style={{ color: forecolor }}>
            {inputdata.fullname}{" "}
            {inputdata?.verified && (
              <OverlayTrigger overlay={<Tooltip>Verified</Tooltip>}>
                <VerifiedUserIcon
                  className="text-success pointer"
                  style={{
                    borderRadius: "12px",
                    fontSize: "20px !important",
                    padding: "4px !important",
                    backgroundColor: "#fff",
                  }}
                />
              </OverlayTrigger>
            )}
          </h2>
          {inputdata?.userhandle && (
            <div className="d-flex justify-content-center">
              <div className="pt-0 my-0 pr-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: forecolor }}
                  href={`https://healsoc.com/u/${inputdata.userhandle}`}>
                  www.healsoc.com/u/{inputdata.userhandle}
                </a>
              </div>
              <CopyToClipboard
                text={copyText()}
                onCopy={() => setShowModal(true)}>
                <Button variant="pure" className="btn-sm py-0">
                  <div className="float-left">
                    <OverlayTrigger overlay={<Tooltip>Copy Link</Tooltip>}>
                      <FileCopyOutlinedIcon
                        style={{
                          color: linkcolor,
                          fontSize: "18px",
                          marginTop: "-2px",
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                </Button>
              </CopyToClipboard>
            </div>
          )}
          {inputdata?.bio && (
            <div style={{ color: forecolor }}>
              <h5 className="pt-0 my-0 ">{inputdata?.bio}</h5>
            </div>
          )}
          {inputdata?.country && (
            <p
              className="py-0 mb-1 averagefontsize"
              style={{ color: forecolor }}>
              {inputdata?.countryStr}{" "}
              <span className="">
                <ReactCountryFlag
                  countryCode={inputdata?.country}
                  className="emojiFlag"
                  style={{
                    fontSize: "1.4em",
                    lineHeight: "1em",
                  }}
                />
              </span>
            </p>
          )}

          {done &&
            user !== null &&
            isFriend === false &&
            inputdata?.userid?.trim() !== user?.uid?.trim() && (
              <div className="pt-0 pb-2">
                <button
                  className={`btn btn-success btn-sm`}
                  onClick={handleAddFriend}
                  style={{
                    border: `${forecolor === "#fff" && "1px solid #fff"}`,
                  }}>
                  <PersonAddIcon style={{ fontSize: "16px" }} /> Follow
                </button>
              </div>
            )}
          <Link to={link} style={{ color: forecolor }}>
            <PeopleAltIcon style={{ fontSize: "14px", color: forecolor }} />{" "}
            View Network
          </Link>
        </div>
        {user !== null && inputdata?.userid?.trim() === user?.uid?.trim() && (
          <div className="d-flex justify-content-center mt-3 mb-0 pb-0">
            <PrintableCV user={user} data={inputdata} />
          </div>
        )}
        <div className="row justify-content-center mx-3">
          <div className="col-lg-8 mx-0 px-0">
            {inputdata?.exp.length > 0 && (
              <>
                <p className="pt-2 mb-2 pb-0 d-flex justify-content-start  font-weight-bold text-left defaultfontsize">
                  Work Experience ({inputdata?.exp.length}){" "}
                  <span className="ml-2">💼</span>
                </p>
                {inputdata?.exp.length ? (
                  <div className="mb-2 mt-2 roundcornernocolor card p-4 text-left shadow">
                    {inputdata?.exp.map((i, index) => {
                      const skills = i?.skills?.trim() || "";
                      const endStr = i?.endmonth
                        ? `${i?.endmonth.substring(0, 3)} ${i?.endyear}`
                        : "Present";
                      const sDate = i?.sdate
                        ? new Date(i?.sdate).toDateString()
                        : new Date().toDateString();
                      const sDateStr = sDate.slice(4);
                      const eDate = i?.edate
                        ? new Date(i?.edate).toDateString()
                        : new Date().toDateString();

                      const eDateStr =
                        i?.currentlyworking === false ? eDate : "Present";
                      const timeframe = dateDifference(sDateStr, eDateStr);

                      return (
                        <div key={i.created}>
                          <p className="mb-0 font-weight-bold text-left ">
                            {i?.title}
                          </p>
                          <p className="mb-0  text-left normalsmallerfontsize">
                            {i?.company}
                          </p>
                          <p className="mb-0 text-muted text-left  normalsmallerfontsize">
                            {`${sDateStr} - ${
                              eDateStr === "Present"
                                ? eDateStr
                                : eDateStr.slice(4)
                            }`}
                            {timeframe !== "" && (
                              <>
                                <span className="mx-1">&bull;</span>
                                <span> {timeframe}</span>
                              </>
                            )}
                          </p>

                          <p className="text-muted normalsmallerfontsize">
                            {i.location}
                          </p>
                          <div className="mt-2">
                            {i?.description?.split("\n").map((str, id) => (
                              <p
                                key={id}
                                className="mb-0 text-left standardfontsize">
                                {str}
                              </p>
                            ))}
                          </div>
                          {skills?.trim() !== "" && (
                            <p className="mt-4 mb-0 text-dark text-left standardfontsize">
                              <b>Skills:</b> {skills}
                            </p>
                          )}
                          {index !== inputdata?.exp.length - 1 && <hr />}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="mb-4 roundcornernocolor card p-4 text-center shadow">
                    <h1>🚚</h1> Soon...
                  </div>
                )}
              </>
            )}
            <p className="pt-4 mb-2 d-flex justify-content-start font-weight-bold text-left defaultfontsize">
              Education ({inputdata?.edu.length})
              <span className="ml-2">🎓</span>
            </p>
            {inputdata?.edu.length ? (
              <div className="mt-0 mb-2 roundcornernocolor card p-4 text-left shadow">
                {inputdata?.edu.map((i, index) => {
                  // console.log(index);
                  return (
                    <div key={i.created}>
                      <p className="mb-0 font-weight-bold text-left ">
                        {i?.school}
                      </p>
                      <p className="mb-0  text-left normalsmallerfontsize">
                        {i?.degree}
                      </p>
                      <p className="mb-0 text-muted text-left normalsmallerfontsize">{`${i?.startmonth.substring(
                        0,
                        3
                      )} ${i?.startyear} - ${i?.endmonth.substring(0, 3)} ${
                        i?.endyear
                      }`}</p>
                      {i?.awards?.trim() !== "" && (
                        <p className="mt-2 mb-0 text-dark text-left normalsmallerfontsize">
                          {i?.awards?.trim()}
                        </p>
                      )}
                      {index !== inputdata?.edu.length - 1 && <hr />}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mb-4 roundcornernocolor card p-4 text-center shadow">
                <h1>🛵</h1> Soon...
              </div>
            )}
            {inputdata?.train?.length > 0 && (
              <>
                <p className="pt-4 mb-2 d-flex justify-content-start font-weight-bold text-left defaultfontsize">
                  Trainings ({inputdata?.train.length})
                  <span className="ml-2">📚</span>
                </p>
                <div className="mt-0 mb-4 roundcornernocolor card p-4 text-left shadow">
                  {inputdata?.train.map((i, index) => {
                    // console.log(i);
                    const skills = i?.skills?.trim() || "";

                    const sDate = i?.sdate
                      ? new Date(i?.sdate).toDateString()
                      : new Date().toDateString();
                    const sDateStr = sDate.slice(4);
                    const eDate = i?.edate
                      ? new Date(i?.edate).toDateString()
                      : new Date().toDateString();

                    const eDateStr = eDate;
                    return (
                      <div key={i?.created}>
                        <div className="d-flex justify-content-between">
                          <p className="mb-0 font-weight-bold text-left ">
                            {i?.title}
                          </p>
                        </div>
                        <p className="mb-0  text-left  normalsmallerfontsize">
                          {i?.organisation}
                        </p>
                        <p className="mb-0 text-muted text-left  normalsmallerfontsize">
                          {`${sDateStr} - ${
                            eDateStr === "Present"
                              ? eDateStr
                              : eDateStr.slice(4)
                          }`}

                          {/* <span className="mx-1">&bull;</span>
                              {dateDifference(sDateStr, eDateStr)} */}
                        </p>
                        <p className="mb-0 text-muted text-left normalsmallerfontsize">
                          {i?.location}
                        </p>
                        {/* <div 
                             className={`mt-2 mb-0  text-left twolineentry`}> */}
                        <div className="mt-2">
                          {i?.description?.split("\n").map((str, id) => (
                            <p key={id} className="  text-left">
                              {str}
                            </p>
                          ))}
                        </div>

                        {index !== inputdata?.train.length - 1 && <hr />}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {/* <button
          id="save-button"
          className="btn btn-primary"
          onClick={downloadPDF}>
          Download Resume downloadPDF
        </button>
      */}
            <div className="d-flex align-items-end justify-content-center">
              Powered by{" "}
              <div
                className="d-flex align-items-end justify-content-center  pt-0 text-danger  font-weight-bold"
                style={{ width: "110px" }}>
                <FavoriteIcon
                  className="text-danger mr-1"
                  style={{ fontSize: "25px" }}
                />
                <div>
                  HEAL<span className="text-dark">SOC</span>
                </div>
              </div>
            </div>
            {user?.uid === inputdata?.userid && (
              <div>
                {/* <div className="pt-4 mt-4 d-flex justify-content-center">
                  <button
                    id="save-button"
                    className="btn btn-primary text-center"
                    onClick={toPDF}>
                    Download Profile
                  </button>
                </div>
                 */}
                {/* <div className="d-flex justify-content-center my-4">
                  <button
                    id="save-button"
                    className="btn btn-primary"
                    onClick={doSavePDF}>
                    Download Profile
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </div>
        {/* <div className="d-flex justify-content-center my-4">
          <button
            id="save-button"
            className="btn btn-primary"
            onClick={doSavePDF}>
            Download Profile
          </button>
        </div> */}
        {/* <button
          className="btn btn-primary"
          onClick={() => generatePDF(getTargetElement, options)}>
          Download Resume
        </button> */}
        {/* <Pdf targetRef={pdfRef} filename="code-example.pdf">
          {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
        </Pdf> */}
        {/* <BottomBar
            label="Message to Order"
            onclick={this.handleButtonClick}
          /> */}
        <Modal
          show={showmodal}
          onHide={() => setShowModal(false)}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Link Copied
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-0 mb-4 text-center">
            <CheckCircleIcon
              className="text-success"
              style={{ fontSize: "50px" }}
            />
            <br />
            <br />
            You may now share this link with your friends
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="link"
              className="forcenoline noline mr-2"
              onClick={() => setShowModal(false)}>
              <span className="text-muted forcenoline noline">Got it</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  return display;
}

export default CVBlockV1;
