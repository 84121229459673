/*
 * These config settings can be placed in separate config page
 */
export function getAllowedBookingCurrentDates() {
  return ["oct2020", "aug2020", "sep2020", "jul2020"];
}

export function getAllowedCurrentDates() {
  return ["sep2020", "oct2020"];
}

export function getJulyMonth() {
  return "jul";
}

export function getAugustMonth() {
  return "aug";
}

export function getPreviousMonth() {
  return "sep";
}

export function getNextMonth() {
  return "oct";
}

export function getJulyBookingLabel() {
  return "July Booking";
}

export function getAugustBookingLabel() {
  return "August Booking";
}

export function getPreviousMonthBookingLabel() {
  return "September Booking";
}

export function getNextMonthBookingLabel() {
  return "October Booking";
}

export function getNextMonthMasstoUpload() {
  return "oct2020";
}

export function getNextMonthDate() {
  return "October 1, 2020";
}

export function getNextFullMonth() {
  let nextmonth = getNextMonthDate().split(" ");
  return nextmonth[0];
}

export function getCutOffTime() {
  //48 hours
  return 48;
}

export function enableCancelMassBooking() {
  return true;
}

export function enableEditBasicProfile() {
  return true;
}

export function enableFirstBookingReminderAdmin() {
  return false;
}

export function disableBooking() {
  return false;
}

export function enableProfilePhotoUpload() {
  return true;
}

export function enableVerifyEmail() {
  return false;
}

export function getWebVersion() {
  return "0.7.5"; //To force all users to reload
}
