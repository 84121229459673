import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";
import { showAdminMenu } from "../admin/service/admin";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
// import PostAddIcon from "@material-ui/icons/PostAdd";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import HomeTwoToneIcon from "@material-ui/icons/HomeTwoTone";
import PeopleAltTwoToneIcon from "@material-ui/icons/PeopleAltTwoTone";
import { Image } from "react-bootstrap";
import NotificationsTwoToneIcon from "@material-ui/icons/NotificationsTwoTone";
import { convertFireStorageUrl } from "../../utils/url";
import LinkButton from "../blocks/linkButtonBlock";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

class Header extends Component {
  render() {
    const {
      type,
      user,
      hide,
      disablecontrol,
      hidehome,
      invisiblehome,
      showcreate,
      hidefriends,
      hideinvite,
      hidenotifications,
      notificationcount = 0,
      shortcode,
    } = this.props;
    let showAdmin = showAdminMenu(user);
    let homeColor = "text-muted";
    if (invisiblehome) {
      homeColor = "text-white";
    }
    return (
      <div
        style={{
          backgroundColor: "#f8f8f8",
          height: "60px",
        }}>
        <Navbar
          bg=""
          fixed="top"
          // className="shadow-sm"
          style={{
            backgroundColor: "#fff",
            borderBottom: "1px solid #efefef",
            height: "60px",
          }}>
          <Navbar.Text style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            {!type && (
              <Link
                to="/home"
                style={{ textDecoration: "none", marginTop: "10px" }}>
                <div
                  className="d-flex align-items-end justify-content-start float-left pt-0 text-danger  font-weight-bold"
                  style={{ width: "150px" }}>
                  <FavoriteIcon
                    className="text-danger mr-1"
                    style={{ fontSize: "25px" }}
                  />
                  <div>
                    HEAL<span className="text-dark">SOC</span>
                  </div>
                </div>
              </Link>
            )}

            {type === "backonly" && (
              <button
                onClick={() => this.props.history.goBack()}
                className="btn-default btn  mx-0 px-0">
                <div className="float-left" style={{ width: "35px" }}>
                  <ArrowBackOutlinedIcon
                    style={{ fontSize: "30px", color: "#1b73e8" }}
                  />
                </div>
              </button>
            )}
            {type === "backandlogo" && (
              <button
                disabled={disablecontrol}
                onClick={() => this.props.history.goBack()}
                className="btn-default btn py-2 px-0">
                <div
                  className="float-left"
                  style={{ paddingTop: "4px", width: "25px" }}>
                  <ArrowBackIosIcon
                    className="text-dark"
                    style={{ fontSize: "25px" }}
                  />
                </div>
                <div
                  className="d-flex align-items-end justify-content-start float-left p-0 pt-1 text-danger font-weight-bold"
                  style={{ width: "150px" }}>
                  <FavoriteIcon
                    className="text-danger mr-1"
                    style={{ fontSize: "25px" }}
                  />
                  HEAL
                  <span className="text-dark">SOC</span>
                </div>
              </button>
            )}
          </Navbar.Text>
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav">
            <Navbar.Text>
              {!hidehome && (
                <div className="float-right mx-2">
                  <Link
                    className={`d-flex align-items-end btn btn-link ml-0 px-0 my-0 py-0 ${homeColor}`}
                    style={{ textDecoration: "none" }}
                    to="/home">
                    <HomeTwoToneIcon
                      className="text-primary"
                      style={{ fontSize: "24px" }}
                    />
                    <span className="pl-1 float-right d-none d-sm-block font-weight-bold">
                      Home
                    </span>
                  </Link>
                </div>
              )}
              {hide !== "profile" && (
                <div className="float-right ml-2">
                  <Link
                    className="d-flex align-items-end  btn btn-link ml-0 px-0 my-0 py-0 text-muted"
                    style={{ textDecoration: "none" }}
                    to="/profile">
                    {!user?.photoURL && (
                      <AccountCircleTwoToneIcon
                        className="text-dark"
                        style={{ fontSize: "24px" }}
                      />
                    )}
                    {user?.photoURL && (
                      <Image
                        src={convertFireStorageUrl(user.photoURL)}
                        width="24px"
                        height="24px"
                        alt=""
                        roundedCircle
                        style={{
                          zIndex: "1",
                          marginTop: "2px",
                        }}
                      />
                    )}
                    <span className="pl-1 float-right d-none d-sm-block font-weight-bold text-dark">
                      Profile
                    </span>
                  </Link>
                </div>
              )}

              {showcreate && !user && (
                <div className="float-right mx-2">
                  {/* <LinkButton
                    type="frameprimary"
                    link="/register"
                    label="Join Now"
                  /> */}

                  <Link
                    className={`btn btn-sm btn-success d-flex align-items-end`}
                    // style={{ textDecoration: "none" }}
                    to={`/register/${shortcode ? shortcode : ""}`}>
                    <div className="font-weight-bold text-white">
                      <PersonAddIcon
                        style={{ fontSize: "16px" }}
                        className="mr-1"
                      />
                      Join Now
                    </div>
                  </Link>
                </div>
              )}
              {showAdmin === true && (
                <div className="float-right mx-2">
                  <Link
                    className="btn btn-link ml-0 px-0 my-0 py-0"
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    to="/commandcenter">
                    <DvrOutlinedIcon
                      style={{
                        fontSize: "24px",
                        color: "#888",
                        marginRight: "5px",
                      }}
                    />{" "}
                    <span className="float-right d-none d-sm-block">Admin</span>
                  </Link>
                </div>
              )}

              {/* {user && !hideinvite && (
                <div className="float-right mx-2">
                  <Link
                    className={`d-flex align-items-end  btn btn-link ml-0 px-0 my-0 py-0`}
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    to={`/invite/${shortcode}`}>
                    <PersonAddIcon
                      className="text-primary"
                      style={{ fontSize: "24px" }}
                    />
                    <span className="pl-1 float-right d-none d-sm-block font-weight-bold text-dark">
                      Invite
                    </span>
                  </Link>
                </div>
              )} */}
              {user && !hidefriends && (
                <div className="float-right mx-2">
                  <Link
                    className={`d-flex align-items-end  btn btn-link ml-0 px-0 my-0 py-0`}
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    to="/connect">
                    <PeopleAltTwoToneIcon
                      className="text-primary"
                      style={{ fontSize: "24px" }}
                    />
                    <span className="pl-1 float-right d-none d-sm-block font-weight-bold text-dark">
                      My Network
                    </span>
                  </Link>
                </div>
              )}
              {/* {user && !hidenotifications && (
                <div className="float-right mx-2">
                  <Link
                    className={`d-flex align-items-end  btn btn-link ml-0 px-0 my-0 py-0`}
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    to="/notifications"
                  >
                    {notificationcount === 0 && (
                      <NotificationsTwoToneIcon
                        className={"text-primary"}
                        style={{ fontSize: "24px" }}
                      />
                    )}
                    {notificationcount > 0 && (
                      <div className="d-flex justify-content-start">
                        <NotificationsTwoToneIcon
                          className={"wiggle text-primary"}
                          style={{ fontSize: "24px" }}
                        />
                        <div
                          className="d-flex justify-content-center bg-danger text-white defaultsmallfontsize font-weight-bold"
                          style={{
                            width: "16px",
                            height: "16px",
                            borderRadius: "8px",
                            marginLeft: "-8px",
                            zIndex: "1",
                          }}
                        >
                          {notificationcount}
                        </div>
                      </div>
                    )}
                    <span className="pl-1 float-right d-none d-sm-block font-weight-bold text-muted">
                      Notifications
                    </span>
                  </Link>
                </div>
              )} */}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Header);
