import React from "react";
import { Button, Modal } from "react-bootstrap";
import DisplayStreak from "../../common/displayStreak";
import ViewContent from "../../thankyounote/component/viewContent";

function FriendInfoModal({ args, closeHandler }) {
  const { obj, showmodal, type } = args;
  let showstreak = false;
  let daylabel = [];

  if (obj) {
    const data = obj?.streak;
    if (data && data.length > 0) {
      daylabel = [
        { id: 1, label: "S", data: data[0] },
        { id: 2, label: "M", data: data[1] },
        { id: 3, label: "T", data: data[2] },
        { id: 4, label: "W", data: data[3] },
        { id: 5, label: "T", data: data[4] },
        { id: 6, label: "F", data: data[5] },
        { id: 7, label: "S", data: data[6] },
      ];
      showstreak = true;
    }
  }
  return (
    <Modal
      show={showmodal}
      onHide={closeHandler}
      animation={false}
      centered
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header style={{ border: "none" }}></Modal.Header>
      <Modal.Body className="mt-0 mb-4 text-center appearslowly">
        <ViewContent
          item={obj}
          design={0}
          type={type === "sent" ? "sender" : "receiver"}
        />

        <div className="row pt-2 px-4">
          <div className=" col px-0">
            <h2 className="py-0 my-0 text-center text-dark">
              {obj?.totalnotes || 0}
            </h2>
            <p className="text-center text-muted py-0">NOTES</p>
          </div>

          <div className=" col  px-0">
            <h2 className="py-0 my-0 text-center text-dark">
              {obj?.totalsent || 0}
            </h2>

            <p className="text-center text-muted py-0">SENT</p>
          </div>
          <div className=" col px-0">
            <h2 className="py-0 my-0 text-center text-dark">
              {obj?.totalreceived || 0}
            </h2>

            <p className="text-center text-muted py-0">RECEIVED</p>
          </div>
        </div>

        {showstreak && (
          <div className="card lightframecolor roundcornernocolor">
            <div className="row p-4">
              {daylabel.map((item) => (
                <div key={item.id} className="col text-center px-0">
                  <DisplayStreak label={item.label} data={item.data} />
                </div>
              ))}
            </div>
          </div>
        )}
        {!showstreak && (
          <div className="text-center py-4">No Activities Yet</div>
        )}
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button
          variant="link"
          className="forcenoline noline mr-2"
          onClick={closeHandler}
        >
          <span className="text-muted forcenoline noline">Close</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FriendInfoModal;
