import React, { useState } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import { dateDifference } from "../../utils/date";

const styles = StyleSheet.create({
  spacing: {
    marginBottom: 8,
  },
  verticalSpacing: {
    marginBottom: 8,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    // fontFamily: "Open Sans",
  },
  name: {
    fontSize: 24,
    textAlign: "left",
    // fontFamily: "Open Sans",
  },
  userhandle: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 4,
  },
  bio: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 5,
  },
  country: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 4,
  },
  heading: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    marginTop: 5,
  },
  sub: {
    fontSize: 11,
    textAlign: "left",
    color: "#6a6a6a",
    marginTop: 2,
  },
  subgray: {
    fontSize: 11,
    color: "#6a6a6a",
    textAlign: "left",
    marginTop: 2,
  },
  description: {
    fontSize: 12,
    textAlign: "left",
    color: "#404040",
    marginBottom: 5,
    marginTop: 5,
  },
  profile: {
    //css with d-flex and justify content start
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "row",
    paddingRight: "20px",
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: 100,
    height: 100,
    marginRight: 20,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  hrule: {
    borderBottom: "1px solid #c0c0c0",
    marginTop: 10,
    marginBottom: 4,
  },
});

function PrintableCV({ user, data }) {
  const [dataUri, setDataUri] = useState(null);
  // console.log(data);
  const options = {
    httpHeaders: { crossOrigin: "anonymous" },
  };

  // Create Document Component
  const PDFCV = () => (
    <Document>
      <Page style={styles.body}>
        <View style={styles.profile}>
          {data?.bigpic && data?.bigpic !== "" && (
            <Image
              style={styles.image}
              options={options}
              src={{
                uri: dataUri,
                method: "GET",
                headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
                body: "",
              }}
            />
          )}
          <View>
            <Text style={styles.name}>{data?.fullname}</Text>
            <Text style={styles.userhandle}>
              <Link src={`https://healsoc.com/u/${data?.userhandle}`}>
                www.healsoc.com/u/{data?.userhandle}
              </Link>
            </Text>
            <Text style={styles.bio}>{data?.bio}</Text>
            <Text style={styles.country}>{data?.countryStr}</Text>
          </View>
        </View>

        <Text style={styles.heading}>
          Work Experience ({data?.exp?.length})
        </Text>
        <View style={styles.hrule}></View>
        {data?.exp?.map((i, index) => {
          const skills = i?.skills?.trim() || "";

          const sDate = i?.sdate
            ? new Date(i?.sdate).toDateString()
            : new Date().toDateString();
          const sDateStr = sDate.slice(4);
          const eDate = i?.edate
            ? new Date(i?.edate).toDateString()
            : new Date().toDateString();

          const eDateStr = i?.currentlyworking === false ? eDate : "Present";
          const timeframe = dateDifference(sDateStr, eDateStr);

          return (
            <View key={index} style={styles.spacing}>
              <Text style={styles.title}>{i.title}</Text>
              <Text style={styles.sub}>{i.company}</Text>
              <Text style={styles.subgray}>
                {`${sDateStr} - ${
                  eDateStr === "Present" ? eDateStr : eDateStr.slice(4)
                }`}
                {timeframe !== "" && (
                  <View>
                    <Text> &bull;</Text>
                    <Text> {timeframe}</Text>
                  </View>
                )}
              </Text>
              <Text style={styles.subgray}>{i?.location}</Text>
              {(i?.description?.trim() !== "" || skills != "") && (
                <View style={styles.description}>
                  {i?.description?.trim() !== "" && (
                    <Text style={styles.description}>{i?.description}</Text>
                  )}
                  {skills != "" && (
                    <Text style={styles.description}>Skills: {i?.skills}</Text>
                  )}
                </View>
              )}
              {/* {index !== data?.exp.length - 1 && (
                <View style={styles.hrule}></View>
              )} */}
            </View>
          );
        })}
        {data?.edu?.length > 0 && (
          <View>
            <Text style={styles.heading}>Education ({data?.edu?.length}) </Text>
            <View style={styles.hrule}></View>
            {data?.edu?.map((i, index) => {
              return (
                <View key={index} style={styles.spacing}>
                  <Text style={styles.title}>{i.school}</Text>
                  <Text style={styles.sub}>{i.degree}</Text>
                  <Text style={styles.subgray}>
                    {`${i?.startmonth.substring(0, 3)} ${
                      i?.startyear
                    } - ${i?.endmonth.substring(0, 3)} ${i?.endyear}`}
                  </Text>
                  {i?.awards && (
                    <View style={styles.description}>
                      <Text style={styles.description}>{i?.awards}</Text>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        )}
        {data?.train?.length > 0 && (
          <View>
            <Text style={styles.heading}>
              Trainings ({data?.train?.length})
            </Text>
            <View style={styles.hrule}></View>
            {data?.train?.map((i, index) => {
              const skills = i?.skills?.trim() || "";

              const sDate = i?.sdate
                ? new Date(i?.sdate).toDateString()
                : new Date().toDateString();
              const sDateStr = sDate.slice(4);
              const eDate = i?.edate
                ? new Date(i?.edate).toDateString()
                : new Date().toDateString();

              const eDateStr = eDate;
              return (
                <View key={index} style={styles.spacing}>
                  <Text style={styles.title}>{i.title}</Text>
                  <Text style={styles.sub}>{i.organisation}</Text>
                  <Text style={styles.subgray}>
                    {`${sDateStr} - ${
                      eDateStr === "Present" ? eDateStr : eDateStr.slice(4)
                    }`}
                  </Text>
                  <Text style={styles.subgray}>{i?.location}</Text>
                  {i?.description?.trim() !== "" && (
                    <View style={styles.description}>
                      {i?.description?.split("\n").map((str, id) => (
                        <Text key={id} style={styles.description}>
                          {str}
                        </Text>
                      ))}
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        )}
      </Page>
    </Document>
  );

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  toDataURL(data?.bigpic).then((dataUrl) => {
    // console.log(dataUrl);
    setDataUri(dataUrl);
  });
  return dataUri ? (
    <PDFDownloadLink
      className="btn btn-primary text-center d-flex justify-content-center"
      document={<PDFCV />}
      fileName={`${data.fullname}.pdf`}>
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download Resume"
      }
    </PDFDownloadLink>
  ) : null;
  // return (
  //   <PDFViewer>
  //     <PDFCV />
  //   </PDFViewer>
  // );
}

export default PrintableCV;
