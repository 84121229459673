import { uuid } from "uuidv4";
import { getParishLinkById } from "../services/parish";
import { isValidParish } from "../services/validation";
/*
 * NOTE: These util functions can be broken in smaller chunks when this grows
 */
/*
 * Name-related functions
 */
export function getFirstnameFromFullname(fullname) {
  let name = fullname.split(" ");
  if (name.length > 1) {
    return name[1];
  }
  return fullname;
}
/*
 * ID-generation functions
 */
export function countDigits(number) {
  let count = 0;
  if (number >= 1) {
    count++;
  }

  while (number >= 10) {
    count++;
    number = number / 10;
  }
  return count;
}
export function getTrimmedID(text) {
  //trim to 9 characters only
  return text.trim().substring(0, 9);
}

export function getRandomId(length) {
  let start = 2; //random generate decimal (eg 0.1234...)
  let end = start + length;
  return Math.random().toString().slice(start, end);
}

export function getDefaultRandomId() {
  return getRandomId(9);
}

export function getUUID() {
  let id = uuid();
  let uid = id.split("-");
  return uid[0];
}
/*
 * Mass-related functions
 */
export function getMassUIDById(uid) {
  if (uid.length < 16) {
    return null;
  }
  return uid.substring(0, 8);
}

export function getMassScheduleString(chosenmass) {
  const { preferredmass, massdetails } = chosenmass;
  return `${massdetails.time}, ${preferredmass.day}, ${preferredmass.date}`;
}

export function getMassRegistrationLink(parishId) {
  let massRegistrationLink = "/masses";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/masses/${link}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getMassRegistrationWithDateLink(parishId, month, year) {
  let massRegistrationLink = "/masses";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/masses/${link}/${year}/${month}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getMassBookingsWithDateLink(parishId, month, year) {
  let massRegistrationLink = "/massbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/massbookings/${link}/${year}/${month}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}

export function getMassBookingsLink(parishId) {
  let massRegistrationLink = "/massbookings";
  if (parishId) {
    if (isValidParish(parishId)) {
      //get link
      let link = getParishLinkById(parishId);
      link = `/massbookings/selector/${link}`;
      massRegistrationLink = link;
    }
  }
  return massRegistrationLink;
}
/*
 * Google Map related
 */
export function getGoogleMapSearchUrl(address) {
  const googleMapSearchUrl = "https://www.google.com/maps/search/?api=1&query=";
  return googleMapSearchUrl + address;
}
/*
 * ID-related functions
 */
export function maskIdentification(id) {
  let maskId;
  let strlen = id.length;

  maskId = "* * * * * " + id.substr(strlen - 4, strlen);
  return maskId;
}

export function mask4DigitId(id) {
  let maskId = "";
  if (id) {
    maskId = "* * * * * " + id;
  } else {
    maskId = "* * * * * ";
  }
  return maskId;
}

export function get4DigitId(id) {
  let maskId;
  let strlen = id.length;

  maskId = id.substr(strlen - 4, strlen);
  return maskId;
}

export function createAnnouncementID(prefix, id) {
  let number = parseInt(prefix);
  return `${number}${id}`;
}

export function createPrayerID(prefix, id) {
  let number = parseInt(prefix);
  return `${number}${id}`;
}

export function maskDOB(dob) {
  let maskdob;
  const usecomplete = false;
  if (!usecomplete) {
    maskdob = dob;
  } else {
    let items = dob.split("/");
    maskdob = `* * / * * / ${items[2]}`;
    // maskdob = `${items[0]} / ${items[1]} / * * * *`;
  }

  return maskdob;
}
/*
 * Time-related
 */

export function formatTime(timeCreated) {
  var diff = Date.now() - timeCreated;

  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };

  if (diff > periods.month) {
    // it was at least a month ago
    return Math.floor(diff / periods.month) + "mon ago";
  } else if (diff > periods.week) {
    return Math.floor(diff / periods.week) + "w ago";
  } else if (diff > periods.day) {
    return Math.floor(diff / periods.day) + "d ago";
  } else if (diff > periods.hour) {
    return Math.floor(diff / periods.hour) + "h ago";
  } else if (diff > periods.minute) {
    return Math.floor(diff / periods.minute) + "m ago";
  }
  return "Just now";
}

export function getMassDateTime(date) {
  const d = date;
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp
  let seconds = d.getSeconds();
  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes += "0";
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}:${seconds} ${mid}`;
}

export function humanReadableDate(date) {
  let masstime = getMassDateTime(date);

  return date.toDateString() + ", " + masstime.toUpperCase();
}

export function formatAnyDate(date) {
  let formattedtime = date;
  if (!isNaN(formattedtime)) {
    if (countDigits(formattedtime) > 10) {
      //cut, get 10 only
      formattedtime = parseInt(formattedtime / 1000);
    }

    let t = new Date(formattedtime * 1000);

    formattedtime = humanReadableDate(t);
  } else {
    let t = new Date(formattedtime);
    formattedtime = humanReadableDate(t);
  }
  return formattedtime;
}

export function getMassTime(date) {
  const d = new Date(date.seconds * 1000);
  // // });
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp

  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}${mid}`;
}

export function convertTo24Hour(time) {
  var hours = parseInt(time.substr(0, 2));
  if (time.indexOf("am") !== -1 && hours === 12) {
    time = time.replace("12", "0");
  }
  if (time.indexOf("pm") !== -1 && hours < 12) {
    time = time.replace(hours, hours + 12);
  }
  return time.replace(/(am|pm)/, "");
}

export function getMassTimeAMPM(date) {
  const d = new Date(date);
  // // });
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp

  let minutes = d.getMinutes();
  if (minutes < 10) {
    // minutes += "0";
    minutes = `0${minutes}`;
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}${mid}`;
}

export function getMassDate(date) {
  const a = new Date(date.seconds * 1000);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function getDate(date) {
  const a = new Date(date.seconds * 1000);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function convertToDate(timestamp) {
  const curdate = new Date(timestamp);
  const year = curdate.getFullYear();
  const month = curdate.getMonth();
  const day = curdate.getDate();
  const date = `${year}-${month + 1}-${day}`;
  // console.log(date);
  return date;
}
export function isWeekEnd(date) {
  let a = new Date(date.seconds * 1000);

  return a.getDay() % 6 === 0;
}

export function isWeekEndMass(date) {
  let a = new Date(date.seconds * 1000);
  let weekEndMass = false;
  //if sunday
  let isSunday = a.getDay() === 0;
  let isConsideredSunday = false;
  //saturday 2pm onwards is considered sunday mass according to Fr Richards
  if (a.getDay() === 6 && a.getHours() >= 14) {
    isConsideredSunday = true;
  }

  if (isSunday || isConsideredSunday) {
    weekEndMass = true;
  }
  return weekEndMass;
}

export function isSaturdayWeekendMass(date) {
  let a = new Date(date.seconds * 1000);
  let result = false;
  //if saturday
  if (a.getDay() === 6 && a.getHours() >= 14) {
    result = true;
  }
  return result;
}

export function isSundayWeekendMass(date) {
  let a = new Date(date.seconds * 1000);
  let result = false;
  //if sunday
  if (a.getDay() === 0) {
    result = true;
  }
  return result;
}

export function isSaturdayMass(date) {
  let a = new Date(date.seconds * 1000);

  //if saturday
  return a.getDay() === 6;
}

export function isSundayMass(date) {
  let a = new Date(date.seconds * 1000);

  //if saturday
  return a.getDay() === 0;
}

export function getDay(date) {
  let a = new Date(date.seconds * 1000);

  return a.getDay();
}
export function getMonthFromDate(date) {
  const a = new Date(date);
  const months = getShortMonths();
  const month = months[a.getMonth()];

  return month;
}

export function getDayMonthYear(date) {
  const a = new Date(date);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function isDayWeekEnd(date) {
  let a = new Date(date);

  return a.getDay() % 6 === 0;
}

export function getDayFromDate(date) {
  let a = new Date(date);

  return a.getDay();
}

export function getLongMonths() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months;
}

export function getShortMonths() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months;
}

export function getLongMonth(date) {
  let a = new Date(date);

  const months = getLongMonths();
  return months[a.getMonth()];
}

export function getShortMonth(date) {
  let a = new Date(date.seconds * 1000);

  const months = getShortMonths();
  return months[a.getMonth()];
}

export function mapToDay(value) {
  if (value < 0 || value > 7) {
    return "Sunday";
  }
  let weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  return weekday[value];
}

export function createDate(date, time) {
  let ampm = time.substr(time.length - 2, time.length);
  let timeonly = time.substr(0, time.length - 2);
  //force to replace . with :
  timeonly = timeonly.replace(".", ":");
  let timing = `${date} ${timeonly} ${ampm}`;
  // console.log("Timing: ", timing);

  let newdate = new Date(timing);
  // console.log("tmpnewdate: ", newdate);
  return newdate;
}

export function getNormalDate(date) {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  const newdate = `${mo} ${da}, ${ye}`;
  // console.log(newdate);
  return newdate;
}

export function getNormalShortDate(date) {
  const d = new Date(date);
  // const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  // const newdate = `${da} ${mo} ${ye}`;
  const newdate = `${da} ${mo}`;
  // console.log(newdate);
  return newdate;
}

export function getSelectOptionDate(date) {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  const newdate = `${da} ${mo} ${ye}`;
  // console.log(newdate);
  return newdate;
}

export function getMassFullDate(date) {
  const a = new Date(date.seconds * 1000);
  const year = a.getFullYear();
  const month = getShortMonth(date);
  const day = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return day + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
}

export function getDayString(date) {
  const a = new Date(date.seconds * 1000);

  return mapToDay(a.getDay());
}

export function getMassScheduleDate(date) {
  const a = new Date(date.seconds * 1000);
  const year = a.getFullYear();
  const month = getShortMonth(date);
  const day = a.getDate();
  return day + " " + month + " " + year;
}

export function isWithinCheckInRange(
  massdate,
  masscheckinopen,
  masscheckinclose,
  localtime
) {
  let result = false;
  const opencheckin = (massdate.seconds - masscheckinopen * 60) * 1000;
  const closecheckin = (massdate.seconds + masscheckinclose * 60) * 1000;
  console.log(`Open: ${opencheckin} Close: ${closecheckin}`);
  if (localtime >= opencheckin && localtime <= closecheckin) {
    result = true;
  }
  return result;
}

export function getLocalTime() {
  const sgtime = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Singapore",
  });
  return new Date(sgtime).getTime();
}
/*
 * Some constants
 */

export function getSuperAdminCode() {
  return "9999";
}

export function getArchdioceseCode() {
  return "99999";
}
