import React, { useState } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { getWebVersion } from "../../services/settings";
import { Button, Modal } from "react-bootstrap";
const Footer = () => {
  const [showmodal, setShowModal] = useState(false);
  return (
    <React.Fragment>
      <div className="clearfix"></div>
      <div className="col-12 pt-0 mt-0 mb-0 text-center">
        <p className="pt-4 mt-4 mb-0 text-muted">
          &copy; {new Date().getFullYear()} HealSoc v{getWebVersion()}
          <br /> All Rights Reserved.
        </p>
        {/* <p className="text-muted">
          Made with{" "}
          <span role="img" aria-label="with love">
            ❤️
          </span>{" "}
          by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/jeynergilcaga">
            Jeyner
          </a>
        </p> */}

        <p
          className="text-muted pt-4 mt-4 mb-1"
          // style={{ borderTop: "1px dashed #d0d0d0" }}
        >
          Need help? Suggestions?
        </p>
        <p className="text-muted mb-1">
          Please feel free to{" "}
          <span className="pointer btn-link" onClick={() => setShowModal(true)}>
            contact me.
          </span>
        </p>
      </div>
      <Modal
        show={showmodal}
        onHide={() => {
          setShowModal(false);
        }}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Contact Me
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="font-weight-normal mt-0 mb-4 text-center text-danger">
          <p
            className="text-dark  mb-1"
            // style={{ borderTop: "1px dashed #d0d0d0" }}
          >
            Need help? Suggestions?
          </p>
          <p className="text-dark mb-1">Please feel free to contact me.</p>
          <p className="text-muted mt-4 mb-1">
            <LinkedInIcon className="mr-1" style={{ color: "#0B65C2" }} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/jeynergilcaga">
              jeynergilcaga
            </a>
          </p>
          <p className="text-muted">
            <FacebookIcon className="mr-1" style={{ color: "#4064AD" }} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://m.me/jeynergil">
              jeynergil
            </a>
          </p>
        </Modal.Body>
        <div className="d-flex justify-content-end px-4 pb-4 ">
          <div>
            <Button
              variant="link"
              className="forcenoline noline mr-2"
              onClick={() => {
                setShowModal(false);
              }}>
              <span className="text-muted forcenoline noline">Close</span>
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Footer;
