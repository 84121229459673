import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import InsertPhotoOutlinedIcon from "@material-ui/icons/InsertPhotoOutlined";

const WelcomePage1 = () => {
  const [imagepost, setImagePost] = useState([]);
  const [file, setFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const uploadSingleFile = (e) => {
    const selected = e.target.files[0];
    // URL.createObjectURL()
    console.log(selected);
    if (selected) {
      const imageurl = URL.createObjectURL(selected);
      setImageUrl(imageurl);

      toDataURL(imageurl, function (dataUrl) {
        console.log("RESULT:", dataUrl);
        setFile(dataUrl);
      });
    }
  };

  const upload = (e) => {
    console.log(e);
    e.preventDefault();
    if (file) {
      console.log("calling to data url");
      toDataURL(file, function (dataUrl) {
        console.log("RESULT:", dataUrl);
      });
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImagePost(imageList);
  };

  const toDataURL = (src, callback, outputFormat) => {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  };

  const handleRemovePhoto = (index, onImageRemove) => {
    onImageRemove(index);
  };

  return (
    <div className="p-4 m-4">
      <p>Image Upload Test: </p>
      {/* {imageUrl && (
        <img
          width="100%"
          className="roundcornernocolor mb-4"
          src={imageUrl}
          alt=""
        />
      )} */}
      {/* <div className="pointer">
        <input
          type="file"
          className="form-control"
          onChange={uploadSingleFile}
          accept="image/png,image/jpg,image/jpeg"
          id="icon-button-file"
          style={{ display: "none" }}
        />
        <label htmlFor="icon-button-file">
          <InsertPhotoOutlinedIcon
            className="text-success pointer"
            style={{
              marginTop: "-2px",
              fontSize: "20px",
            }}
          />
        </label>
      </div>
      <button
        type="button"
        className="btn btn-primary btn-block"
        onClick={upload}
      >
        Upload
      </button> */}
      <ImageUploading
        value={imagepost}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="pointer">
            <div>
              <div
                className="text-muted"
                onClick={onImageUpload}
                {...dragProps}
              >
                Click to Add Photo
              </div>
            </div>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <div className="image-item__btn-wrapper">
                  <div
                    className="text-danger"
                    onClick={() => handleRemovePhoto(index, onImageRemove)}
                  >
                    Close
                  </div>
                </div>
                <img
                  src={image.data_url}
                  alt=""
                  width="100%"
                  className="roundcornernocolor mb-4"
                />
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default WelcomePage1;
