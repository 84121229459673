import React from "react";
// import PostAddIcon from "@material-ui/icons/PostAdd";
import { Link } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { getWebVersion } from "../../services/settings";

const SystemLogo = ({ refid = "" }) => {
  return (
    <div className="navbar navbar-light text-center flex-column pointer">
      <Link to={`/welcome/${refid}`} className="forcenoline">
        <h1 className="mb-0 pb-0 font-weight-bold text-danger pointer">
          <div className="py-2">
            <FavoriteIcon className="logo-big" />
          </div>
          HEAL<span className="text-dark">SOC</span>
        </h1>
        <p className="text-muted">v{getWebVersion()}</p>
      </Link>
      {/* <p className="averagefontsize text-muted">
        Designed for people who wants to practice daily gratitude.
      </p> */}
    </div>
    // <nav className="navbar navbar-light text-center flex-column">
    //   <a className="navbar-brand mx-auto" href="# ">
    //     <h1 className="mb-0 pb-0 font-weight-bold text-primary">
    //       <div className="py-2">
    //         <PostAddIcon className="logo-big" />
    //       </div>
    //       THANKYNOTE
    //     </h1>
    //   </a>
    //   <p className="averagefontsize">Spread gratitude around the world.</p>
    // </nav>
  );
};

export default SystemLogo;
