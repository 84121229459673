import React from "react";
import { Link } from "react-router-dom";
// import PostAddIcon from "@material-ui/icons/PostAdd";
import FavoriteIcon from "@material-ui/icons/Favorite";
import "../css/welcome.css";
import { Carousel, Image } from "react-bootstrap";
import HomeBannerImage from "../../../img/homeimages/thankynote-ui-design.jpg";
import Dalai from "../../../img/homeimages/dalailama.jpg";
import Oprah from "../../../img/homeimages/oprah.png";
import Eckhart from "../../../img/homeimages/eckhart.png";
import Kennedy from "../../../img/homeimages/kennedy.png";
import Footer from "../../common/footer";
import { getWebVersion } from "../../../services/settings";

const WelcomePage = ({ ...props }) => {
  const params = props.match.params;
  let refid = null;
  if (params) {
    refid = params?.refid || "";
  }
  let el = document.querySelector("title");
  const title = `Welcome to HealSoc`;
  const content = `Join the network of Filipino Health Professionals around the world!`;
  el.innerText = title;
  el = document.querySelector("meta[name='description']");
  el.setAttribute("content", content);
  return (
    <div className="container col-lg-6 mt-4 pt-4">
      <div className="row align-items-center d-flex pt-4 mt-4">
        <div className="text-center container h-50 pb-0 mb-0">
          <h1 className="font-weight-bold text-danger">
            <div className="">
              <FavoriteIcon
                className="logo-big"
                style={{ fontSize: "100px" }}
              />
            </div>
            HEAL<span className="text-dark">SOC</span>
          </h1>
          <p className="text-muted">v{getWebVersion()}</p>
          <p className="defaultfontsize pb-4 text-black">
            This is an initiative to build a united Filipino Health
            Professionals{" "}
            <span role="img" aria-label="ph flag">
              🇵🇭
            </span>
          </p>
          <div style={{ width: "280px" }} className="mx-auto text-center">
            <div className="mt-4 mb-0">
              <Link
                className="font-weight-bold btn-primary btn-lg btn-block px-4 my-4"
                to={`/login/${refid}`}>
                Log In
              </Link>
            </div>
            <div className="mb-4">
              <Link
                className="font-weight-bold btn-success btn-lg btn-block px-4 my-4"
                to={`/register/${refid}`}>
                Create New Account
              </Link>
            </div>
          </div>

          <div className="py-4">
            <hr />
          </div>
          <div className="pt-0 pb-4">
            <span
              role="img"
              aria-label="world"
              className=""
              style={{ fontSize: "80px" }}>
              🌎
            </span>
          </div>
          <h2 className="text-dark font-weight-light">
            Join the network of Filipino Health Professionals around the world!
          </h2>
          {/* <div className="my-5 responsive">
            <img
              className="responsive img-fluid"
              style={{
                borderRadius: "8px",
                boxShadow: "1px 1px 10px -4px #888888",
              }}
              src={HomeBannerImage}
              alt="ThankyNote Home Page"
            />
          </div> */}

          {/* <Carousel indicators={false} controls={true}>
            <Carousel.Item>
              <div
                className="d-block w-100"
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}>
                <span role="img" aria-label="star" style={{ fontSize: "80px" }}>
                  🌟
                </span>
                <h2 className="text-dark font-weight-light pb-4">
                  Be grateful everyday.
                </h2>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-block w-100"
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}>
                <span
                  role="img"
                  aria-label="party"
                  style={{ fontSize: "80px" }}>
                  🎉
                </span>

                <h2 className="text-dark font-weight-light pb-4">
                  Count your blessings.
                </h2>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div
                className="d-block w-100"
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}>
                <span
                  role="img"
                  aria-label="smiley"
                  style={{ fontSize: "80px" }}>
                  😊
                </span>
                <h2 className="text-dark font-weight-light pb-4">
                  Practice daily gratitude.
                </h2>
              </div>
            </Carousel.Item>
          </Carousel> */}
          {/* <div className="py-4">
            <hr />
          </div>
          <div className="py-4">
            <span role="img" aria-label="fire" style={{ fontSize: "80px" }}>
              🔥
            </span>
            <h2 className="text-primary">Our Mission</h2>
            <h2 className="text-dark font-weight-light py-4">
              Our mission is to spread gratitude, one person at a time.
            </h2>
          </div> */}
          {/* <div className="py-4">
            <hr />
          </div> */}
          {/* <div className="pb-4 pt-0 mb-4">
            <span role="img" aria-label="sparkle" style={{ fontSize: "80px" }}>
              ✨
            </span>
            <Carousel variant="dark" fade>
              <Carousel.Item>
                <div
                  className="d-block w-100"
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}>
                  <p className="text-dark py-4">
                    "When you practice gratefulness, there is a sense of respect
                    towards others."
                    <br />
                    <br />
                    <Image
                      src={Dalai}
                      width="30px"
                      height="30px"
                      alt=""
                      roundedCircle
                      style={{
                        zIndex: "1",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                    Dalai Lama
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="d-block w-100"
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}>
                  <p className="text-dark py-4">
                    "Be thankful for what you have, you'll end up having more."
                    <br />
                    <br />
                    <Image
                      src={Oprah}
                      width="30px"
                      height="30px"
                      alt=""
                      roundedCircle
                      style={{
                        zIndex: "1",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                    Oprah Winfrey
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="d-block w-100"
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}>
                  <p className="text-dark py-4">
                    "We must find time to stop and thank the people who make a
                    difference in our lives."
                    <br />
                    <br />
                    <Image
                      src={Kennedy}
                      width="30px"
                      height="30px"
                      alt=""
                      roundedCircle
                      style={{
                        zIndex: "1",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                    John F. Kennedy
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="d-block w-100"
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}>
                  <p className="text-dark py-4">
                    "Acknowledging the good that you already have in your life
                    is the foundation for all abundance."
                    <br />
                    <br />
                    <Image
                      src={Eckhart}
                      width="30px"
                      height="30px"
                      alt=""
                      roundedCircle
                      style={{
                        zIndex: "1",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                    Eckhart Tolle
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div> */}

          {/* <div className="py-4">
            <hr />
          </div>

          <div className="pt-0">
            <span role="img" aria-label="heart" style={{ fontSize: "80px" }}>
              ❤️
            </span>
            <h2 className="text-primary mb-4 pb-4">
              Start Being Grateful Today
            </h2>
            <div style={{ width: "280px" }} className="mx-auto text-center">
              <div className="mt-4 mb-0">
                <Link
                  className="font-weight-bold btn-primary btn-lg btn-block px-4 my-4"
                  to={`/login/${refid}`}>
                  Log In
                </Link>
              </div>
              <div className="">
                <Link
                  className="font-weight-bold btn-success btn-lg btn-block px-4 my-4"
                  to={`/register/${refid}`}>
                  Create New Account
                </Link>
              </div>
            </div>
          </div> */}
          <div className="my-4 py-4">
            <hr />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
