import React, { useState } from "react";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Modal } from "react-bootstrap";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function Invite({ refid }) {
  const [showmodal, setShowModal] = useState(false);
  const copyText = () => {
    // if (text.length > 70) {
    //   //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    //   text = `${this.shorten(text, 83)}... See more.`;
    // }
    const texttocopy = `Hi,

I am inviting you to join my HealSoc Network.
    
👉 https://healsoc.com/u/${refid}`;
    return texttocopy;
  };

  return (
    <div className="pb-3">
      <span style={{ fontSize: "30px" }} role="img" aria-label="Nothing">
        👩🏻‍⚕️🧑🏻‍⚕️
      </span>
      <p className="mt-0 mb-2">
        Invite your friends and classmates in the Medical Field 🌎
      </p>

      <CopyToClipboard text={copyText()} onCopy={() => setShowModal(true)}>
        <Button size="sm" variant="success" className="btn-sm pl-2 pr-3">
          <div className="float-left" style={{ width: "20px" }}>
            <FileCopyOutlinedIcon
              className="text-white"
              style={{
                fontSize: "14px",
                marginTop: "-2px",
              }}
            />
          </div>
          <div className="float-left">Invite</div>
        </Button>
      </CopyToClipboard>
      <Modal
        show={showmodal}
        onHide={() => setShowModal(false)}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Link Copied
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0 mb-4 text-center">
          <CheckCircleIcon
            className="text-success"
            style={{ fontSize: "50px" }}
          />
          <br />
          <br />
          You may now share this link with your friends
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            variant="link"
            className="forcenoline noline mr-2"
            onClick={() => setShowModal(false)}>
            <span className="text-muted forcenoline noline">Got it</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Invite;
