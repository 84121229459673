import React from "react";
import FriendList from "./friendList";
import NoFriends from "./noFriends";
import ScrollToTop from "../../common/scrollToTop";
import LinkButton from "../../blocks/linkButtonBlock";

function NetworkList({ type, list, handler, state }) {
  return (
    <div className="py-4">
      <div>
        {list &&
          list.map((entry, index) => {
            // console.log(entry);
            return (
              <div key={index}>
                <FriendList
                  entry={entry}
                  action="viewprof"
                  clickHandler={() => handler(entry)}
                />
                {index - 1 !== list.length && (
                  <div style={{ borderBottom: "1px dashed #d0d0d0" }} />
                )}
              </div>
            );
          })}
      </div>
      {/* {list?.length === 0 && <NoFriends userhandle={state.userhandle} />} */}
      <div className="py-4">
        {list?.length === 0 &&
          type === "following" &&
          "Not following anyone yet."}
        {list?.length === 0 && type === "follower" && "No followers yet."}
      </div>
      {list?.length > 5 ? (
        <>
          <ScrollToTop />
          <LinkButton type="link" link="/home" label="Back to Home" />
        </>
      ) : (
        <LinkButton type="link" link="/home" label="Back to Home" />
      )}
    </div>
  );
}

export default NetworkList;
