/*
  let newDate = endDate;
  if (endDate === "Present") {
    newDate = new Date();
  }
  // Convert the start and end dates to JavaScript Date objects.
  const start = new Date(startDate);
  const end = new Date(newDate);

*/

export function normaliseDate(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function dateDifference(start, end) {
  let style = 4;
  if (style === 1) {
    return dateDifferenceV1(start, end);
  } else if (style === 2) {
    return dateDifferenceV2(start, end);
  } else if (style === 3) {
    return dateDifferenceV3(start, end);
  } else if (style === 4) {
    return dateDifferenceV4(start, end);
  }
}

export function dateDifferenceV4(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);
  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ).getDate();
    days = lastDayOfMonth - startDate.getDate() + endDate.getDate();
  }

  if (months < 0) {
    years--;
    months = 12 + months;
  }

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  console.log(years, months);
  if (years === 0 && months === 0 && days > 0) {
    result.push(`${days} day${days !== 1 ? "s" : ""}`);
  }

  return result.join(" ");
}

export function dateDifferenceV3(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);

  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ).getDate();
    days += lastDayOfMonth;
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  const yearStr = years > 0 ? `${years} yr${years > 1 ? "s" : ""}` : "";
  const monthStr = months > 0 ? `${months} mo${months > 1 ? "s" : ""}` : "";
  const dayStr = days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "";

  const result = [yearStr, monthStr, dayStr].filter((part) => part).join(" ");

  return result;
}

export function dateDifferenceV2(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);

  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() - 1,
      startDate.getDate()
    );
    days = Math.floor((endDate - lastMonth) / (24 * 60 * 60 * 1000));
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  if (years === 0 && months === 0 && days === 0) {
    return "";
  }

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  if (days > 0) {
    result.push(`${days} day${days > 1 ? "s" : ""}`);
  }

  return result.join(" ");
}

export function dateDifferenceV1(date1, date2) {
  let newDate = date2;
  if (date2 === "Present") {
    newDate = new Date();
  }
  // Convert the start and end dates to JavaScript Date objects.

  const d1 = new Date(date1);
  const d2 = new Date(newDate);

  if (d1 > d2) {
    [d1, d2] = [d2, d1]; // Swap the dates if d1 is later than d2
  }

  const delta = Math.abs(d2 - d1);
  const years = Math.floor(delta / (365 * 24 * 60 * 60 * 1000));

  const remainingDays = delta % (365 * 24 * 60 * 60 * 1000);
  const months = Math.floor(remainingDays / (30 * 24 * 60 * 60 * 1000));
  const days = Math.floor(
    (remainingDays % (30 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
  );

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  if (days > 0) {
    result.push(`${days} day${days > 1 ? "s" : ""}`);
  }

  return result.length > 0 ? result.join(" ") : "0 days";
}

export function getAge(sDate, eDate = "Present") {
  // console.log(sDate, eDate);
  let nDate = eDate;
  let diffTime;
  if (eDate === "Present") {
    diffTime = new Date() - new Date(sDate);
  } else {
    diffTime = new Date(nDate) - new Date(sDate);
  }
  console.log(diffTime);
  const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const years = Math.floor(totalDays / 365.25);
  const months = Math.floor((totalDays % 365.25) / 30.4375);
  console.log(totalDays);
  let yStr = "";
  let mStr = "";
  if (years > 0) {
    yStr = `${years} yr${years > 1 ? "s" : ""}`;
  }

  if (months > 0) {
    mStr = `${months} mo${months > 1 ? "s" : ""}`;
  }
  console.log(`${yStr} ${mStr}`);
  return `${yStr} ${mStr}`;
}
